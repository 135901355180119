import { defineStore } from 'pinia';

const query = {
    search: '',
    status: ['new','active']
  }

export const useCommunityStore = defineStore('community', {
    state: () => {
        return {
            query: {...query},
            page: 1
        }
    },
    getters: {
        hasFilters() {
            return JSON.stringify(this.query) != JSON.stringify(query)
        }
    },
    actions: {
        setPage(page) {
            if(typeof page == 'number') {
                this.page = page;
                return;
            }
            if(page == 'next') {
                this.page++;
            } else {
                this.page--;
            }
        },
        clear() {
            this.query = {...query}
        }
    }
})