<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Available Homes</h2>
    </div>

    <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4 mt-8">
        <div class="mb-4 flex-initial">
            <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
            <input v-model="query.search" placeholder="Search available homes by keyword" id="search" name="search"
            type="text" autocomplete="off"
            class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-100 border rounded-md h-8">
        </div>
        <div v-if="availableCollections.length > 1" class="mb-4 flex-initial">
            <SelectField v-model="query.collection" label="Collections">
                <option :value="null">All Collections</option>
                <option v-for="collection,i in availableCollections" :key="`collection-${i}`" :value="collection.community_rid">{{ collection.meta.info.name }}</option>
            </SelectField>
        </div>
        <div v-if="availableFloorplans.length > 1" class="mb-4 flex-initial">
            <SelectField v-model="query.floorplan" label="Floorplans">
                <option :value="null">All Floorplans</option>
                <option v-for="floorplan,i in availableFloorplans" :key="`floorplan-${i}`" :value="floorplan.kova.ModelRID">{{ floorplan.meta.info.name }}</option>
            </SelectField>
        </div>
        <div class="mb-4 flex-initial">
            <SelectMultipleField v-model="query.status" label="Published" :options="statuses" empty="All Statuses"/>
        </div>
        <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
            <FormButton @click="clear" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
        </div>
    </div>

    <PaginationTable :data="filtered" class="flex flex-col mt-8">
        <template #headers>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Available Home
            </th>
            <th v-if="availableCollections.length" scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Collection
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Floorplan
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Sales Status
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider lg:w-1/5">
                Status
            </th>
        </template>
        <template #columns="{row}">
            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name: 'available-homes-show', params: { id: row.id } }" class="px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600 block">
                    {{ `${row.kova?.Lot?.StreetAddress}, ${row.kova?.Lot?.City} ${row.kova?.Lot?.State} ${row.kova?.Lot?.ZipCode}` }}
                </router-link>
            </td>

            <td v-if="availableCollections.length" class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                {{ getCollection(row.kova.CommunityRID)?.meta.info.name }}
            </td>

            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                {{ getFloorplan(row.kova.ModelRID)?.meta.info.name }}
            </td>

            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class="!row.kova?.Status == 'Released' ? 'bg-oakwood-red-100 text-oakwood-red-800' : 'bg-oakwood-green-100 text-oakwood-green-800'">
                    {{ row.kova?.Status }}
                </span>
            </td>

            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900 flex items-center gap-2">
                <ToggleButton :id="`item-${row.id}-status`" @changed="save(row)" :checked="row.status == 'active'" 
                :label="row.status == 'active' ? 'Published' : 'Unpublished'"/>
                <label :for="`item-${row.id}-status`">{{ statuses.find(status => status.value == row.status).label }}</label>
            </td>
        </template>
    </PaginationTable>
       
</template>

<script>
import axios from 'axios';
import {FormButton, SelectField, SelectMultipleField, ToggleButton} from '@/components/form';
import PaginationTable from '@/components/PaginationTable.vue';
import { statuses } from '@/helpers/globals';
import { clean } from '@/helpers/regex';

const query = {
    search: '',
    collection: null,
    floorplan: null,
    status: ['active', 'new']
}

export default {
    data() {
        return {
            statuses,
            query: {...query}
        }
    },
    emits: [
        'update'
    ],
    props: {
        sales_orders: {
            type: Array,
            required: true
        },
        floorplans: {
            type: Array,
            required: true
        }
    },
    methods: {
        save(item) {
            item.status = item.status != 'active' ? 'active' : 'inactive';
            axios.patch(`sales_orders/${item.id}`, item)
                .then(res => {
                    if(res.data?.id) {
                        this.$emit('update',res.data);
                    }
                })
                .catch(e => {
                    throw e;
                });
        },
        getFloorplan(model_rid, community_rid) {
            return this.floorplans.find(floorplan => floorplan.kova.ModelRID == model_rid) || false;
        },
        getCollection(id) {
            return this.collections.find(community => community.kova.CommunityRID == id) || false;
        },
        clear() {
            this.query = {...query};
        }
    },
    computed: {
        filtered() {
            let results = this.sales_orders;
            if (this.query.status.length) {
                results = results.filter(item => this.query.status.includes(item.status));
            }

            if (this.query.floorplan) {
                results = results.filter(item => item.kova.ModelRID == this.query.floorplan);
            }

            if (this.query.collection) {
                results = results.filter(item => item.kova.CommunityRID == this.query.collection);
            }

            if(this.query.search) {
                const regex = new RegExp(clean(this.query.search), 'i');
                results = results.filter(result => regex.exec(result.meta?.info?.description) || regex.exec(result.kova.Lot.StreetAddress))
            }

            return results;
        },
        availableFloorplans() {
            const model_rids = [...new Set(this.sales_orders.map(sales_order => sales_order.kova.ModelRID))];
            return this.floorplans.filter(floorplan => model_rids.includes(floorplan.kova.ModelRID)).sort((a,b) => a.meta.info.name > b.meta.info.name ? 1 : -1);
        },
        collections() {
            if(!this.floorplans[0]?.collection) return [];
            return [...new Set(this.floorplans.map(floorplan => JSON.stringify(floorplan.collection)))].map(floorplan => JSON.parse(floorplan))
        },
        availableCollections() {
            const community_rids = [...new Set(this.sales_orders.map(sales_order => sales_order.kova.CommunityRID))];
            return this.collections.filter(collection => community_rids.includes(collection.community_rid)).sort((a,b) => a.meta.info.name > b.meta.info.name ? 1 : -1);

        },
        hasFilters() {
            return JSON.stringify(query) !== JSON.stringify(this.query);
        }
    },
    components: {
        ToggleButton,
        PaginationTable,
        SelectMultipleField,
        SelectField,
        FormButton
    }
}
</script>