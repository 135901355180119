const positions = [
    'Elevation',
    'Floorplan',
    'Interior'
];
const floorplan = {
    ModelStatus: {
        "N/S" : "Not Selected",
        "Internal" : "Internal",
        "Pre-Sale (VIP)" : "Pre-Sale (VIP)",
        "Pre-Sale (Floorplans)" : "Pre-Sale (Floorplans)",
        "On Sale" : "On Sale",
        "Sold Out" : "Sold Out",
        "Off Market" : "Off Market",
        QMI : "Available Homes (QMI)"
    }
}


const measurements = [
    {
        label: 'Inch',
        value: 'inch'
    },
    {
        label: 'Foot',
        value: 'foot'
    },
    {
        label: 'US Survey Foot',
        value: 'USSurveyFoot'
    },
    {
        label: 'Mile',
        value: 'mile'
    },
    {
        label: 'Millimeter',
        value: 'millimeter'
    },
    {
        label: 'Centimeter',
        value: 'centimeter'
    },
    {
        label: 'Meter',
        value: 'meter'
    },
    {
        label: 'Kilometer',
        value: 'kilometer'
    }
];

const states = [
    {
        value: 'AK',
        label: 'Alaska'
    },
    {
        value: 'AL',
        label: 'Alabama'
    },
    {
        value: 'AR',
        label: 'Arkansas'
    },
    {
        value: 'AZ',
        label: 'Arizona'
    },
    {
        value: 'CA',
        label: 'California'
    },
    {
        value: 'CO',
        label: 'Colorado'
    },
    {
        value: 'CT',
        label: 'Connecticut'
    },
    {
        value: 'DC',
        label: 'District of Columbia'
    },
    {
        value: 'DE',
        label: 'Delaware'
    },
    {
        value: 'FL',
        label: 'Florida'
    },
    {
        value: 'GA',
        label: 'Georgia'
    },
    {
        value: 'HI',
        label: 'Hawaii'
    },
    {
        value: 'IA',
        label: 'Iowa'
    },
    {
        value: 'ID',
        label: 'Idaho'
    },
    {
        value: 'IL',
        label: 'Illinois'
    },
    {
        value: 'IN',
        label: 'Indiana'
    },
    {
        value: 'KS',
        label: 'Kansas'
    },
    {
        value: 'KY',
        label: 'Kentucky'
    },
    {
        value: 'LA',
        label: 'Louisiana'
    },
    {
        value: 'MA',
        label: 'Massachusetts'
    },
    {
        value: 'MD',
        label: 'Maryland'
    },
    {
        value: 'ME',
        label: 'Maine'
    },
    {
        value: 'MI',
        label: 'Michigan'
    },
    {
        value: 'MN',
        label: 'Minnesota'
    },
    {
        value: 'MO',
        label: 'Missouri'
    },
    {
        value: 'MS',
        label: 'Mississippi'
    },
    {
        value: 'MT',
        label: 'Montana'
    },
    {
        value: 'NC',
        label: 'North Carolina'
    },
    {
        value: 'ND',
        label: 'North Dakota'
    },
    {
        value: 'NE',
        label: 'Nebraska'
    },
    {
        value: 'NH',
        label: 'New Hampshire'
    },
    {
        value: 'NJ',
        label: 'New Jersey'
    },
    {
        value: 'NM',
        label: 'New Mexico'
    },
    {
        value: 'NV',
        label: 'Nevada'
    },
    {
        value: 'NY',
        label: 'New York'
    },
    {
        value: 'OH',
        label: 'Ohio'
    },
    {
        value: 'OK',
        label: 'Oklahoma'
    },
    {
        value: 'OR',
        label: 'Oregon'
    },
    {
        value: 'PA',
        label: 'Pennsylvania'
    },
    {
        value: 'RI',
        label: 'Rhode Island'
    },
    {
        value: 'SC',
        label: 'South Carolina'
    },
    {
        value: 'SD',
        label: 'South Dakota'
    },
    {
        value: 'TN',
        label: 'Tennessee'
    },
    {
        value: 'TX',
        label: 'Texas'
    },
    {
        value: 'UT',
        label: 'Utah'
    },
    {
        value: 'VA',
        label: 'Virginia'
    },
    {
        value: 'VT',
        label: 'Vermont'
    },
    {
        value: 'WA',
        label: 'Washington'
    },
    {
        value: 'WI',
        label: 'Wisconsin'
    },
    {
        value: 'WV',
        label: 'West Virginia'
    },
    {
        value: 'WY',
        label: 'Wyoming'
    }
];

const statuses = [
    {
      label : 'Published',
      value : 'active'
    },
    {
      label : 'Unpublished',
      value : 'inactive'
    },
    {
      label : 'New',
      value : 'new'
    }
];

const agentStatuses = [
    {
        label : 'Active',
        value : 'active'
    },
    {
        label : 'Inactive',
        value : 'inactive'
    }
]

const systems = [
    {
        label: 'GPS (latitude/longitude)',
        value: 'EPSG:4326'
    },
    {
        label: 'State Planes (NAD83)',
        value: 'EPSG:4269' 
    },
    {
        label: 'Web Mercator',
        value: 'EPSG:3857'
    }
];

const handle404 = (context, error) => {
    let route = {
        name: 404, 
        params: { 
            pathMatch: context.$route.path.substring(1).split('/'),
        },
        query: {
            error: error || false
        }
    }
    console.log(route);
    context.$router.push(route);
}

export {
    handle404,
    positions,
    floorplan,
    measurements,
    states,
    statuses,
    agentStatuses,
    systems,
}