<template>

<div class="flex items-center justify-center h-screen">
    <section class="px-6 py-5 text-white bg-oakwood-red-600 border-oakwood-red-600 border shadow-lg rounded flex flex-col gap-6">
        <h1 class="text-2xl leading-6 font-medium">
            <span class="far fa-warning pr-1"></span>Page Not Found
        </h1>
        <p class="text-sm leading-5 font-medium">
            {{ error }}
        </p>
        <p>
            <FormButton icon="fa-chevron-left" class="bg-white text-oakwood-red-600 hover:text-oakwood-red-700" @click="back()">
                Go Back
            </FormButton>
        </p>
    </section>
</div>
</template>
<script>
import FormButton from '@/components/form/FormButton.vue';

export default {
    data() {
        return {
            error: this.$route.query?.error || 'The page you were attempting to access could not be found.'
        }
    },
    methods: {
        back() {
            this.$router.options.history.state.back === this.$router.options.history.state.current ? this.$router.go(-2) : this.$router.back();
        }
    },
    components: {
        FormButton
    },
}
</script>