<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Sales Office</h2>
        <nav v-if="user.level >= 2" class="flex items-end gap-2">
            <FormButton @click="edit()" v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil">
                Edit
            </FormButton>
            <template v-else>
                <FormButton @click="cancel()" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300" icon="fa-cancel">
                    Cancel
                </FormButton>
                <FormButton @click="save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-save">
                    Save
                </FormButton>
            </template>
        </nav>

    </div>
    <dl class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-if="!editing">
        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Address</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <p v-if="collection.meta?.sales_office?.address?.street1">{{ collection.meta?.sales_office?.address?.street1 }}</p>
            <p v-if="collection.meta?.sales_office?.address?.street2">{{ collection.meta?.sales_office?.address?.street2 }}</p>
            <p v-if="collection.meta?.sales_office?.address?.city && collection.meta?.sales_office?.address?.state && collection.meta?.sales_office?.address?.zip">{{ collection.meta?.sales_office?.address?.city }} {{ collection.meta?.sales_office?.address?.state }} {{ collection.meta?.sales_office?.address?.zip }}</p>  
        </dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Geolocation</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <p>Latitude: {{ collection.meta?.sales_office?.geo?.lat }}º</p>
            <p>Longitude: {{ collection.meta?.sales_office?.geo?.lng }}º</p>
        </dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Located Inside Collection</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ collection.meta?.sales_office?.address?.in_collection ? "Yes" : "No" }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Leads Email</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ collection.meta?.sales_office?.email }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Phone</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ collection.meta?.sales_office?.phone }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4 lg:row-span-7">Hours</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4" v-for="day,i in collection.meta?.sales_office?.hours" :key="`hours-${i}`">
            {{ (new Intl.DateTimeFormat('en-us', { weekday:'long' })).format(new Date(0, 0, (i + 1))) }}:
            <template v-if="day?.appointment || false">
                By Appointment
            </template>
            <template v-else>
            {{ day.start ? new Intl.DateTimeFormat('en-us', { hour:'numeric', minute:"2-digit", hour12: true })
                .formatRange(
                    new Date(2024, 0, (i + 1), ...time(day.start)),
                    new Date(2024, 0, (i + 1), ...time(day.end))
                ) :
                'Closed'
            }}
            </template>
        </dd>

    </dl>

    <fieldset class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-else-if="user.level >= 2">
        <legend class="sr-only">Sales Office Settings</legend>

        <h3 class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Office Address</h3>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 grid lg:grid-cols-3 gap-2">
            <p class="lg:col-span-3 flex items-center gap-2">
                <ToggleButton id="sales_office.address.in_collection" @changed="(e) => { sales_office.address.in_collection = !sales_office.address.in_collection }" :checked="sales_office.address.in_collection" 
                      :label="sales_office.address.in_collection ? 'In the Collection' : 'Outside the Collection'"/>
                <label for="sales_office.address.in_collection" class="text-sm leading-5 font-medium text-oakwood-gray-500">Address is Inside the Collection</label>
            </p>
            <p class="lg:col-span-3 grid gap-2">
                <label for="sales_office.address.street1" class="text-sm leading-5 font-medium text-oakwood-gray-500">Street Address 1</label>
                <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="sales_office.address.street1" v-model="sales_office.address.street1"/>
            </p>
            <p class="lg:col-span-3 grid gap-2">
                <label for="sales_office.address.street2" class="text-sm leading-5 font-medium text-oakwood-gray-500">Street Address 2</label>
                <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="sales_office.address.street2" v-model="sales_office.address.street2"/>
            </p>
            <p class="grid gap-2">
                <label for="sales_office.address.city" class="text-sm leading-5 font-medium text-oakwood-gray-500">City</label>
                <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="sales_office.address.city" v-model="sales_office.address.city"/>
            </p>
            <p class="grid gap-2">
                <label for="sales_office.address.state" class="text-sm leading-5 font-medium text-oakwood-gray-500">State</label>
                <SelectField id="sales_office.address.state" class="w-full" v-model="sales_office.address.state">
                    <option :value="null">Select a State...</option>
                    <option v-for="state, i in states" :key="`state-${i}`" :value="state.value">{{ state.label }}</option>
                </SelectField>
            </p>
            <p class="grid gap-2">
                <label for="sales_office.address.zip" class="text-sm leading-5 font-medium text-oakwood-gray-500">Zip Code</label>
                <NumberField id="sales_office.address.zip" width="w-full" :min="0" :max="99999" :arrows="false" v-model="sales_office.address.zip" />
            </p>
        </div>

        <p class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Geolocation</p>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 grid lg:grid-cols-6 gap-2">
            <p class="grid gap-2">
                <label for="sales_office.geo.lat" class="text-sm leading-5 font-medium text-oakwood-gray-500">Latitude</label>
                <NumberField suffix="º" id="sales_office.geo.lat" width="w-full" step="0.00000000000001" :max="90" :min="-90" v-model="sales_office.geo.lat" />
            </p>
            <p class="grid gap-2">
                <label for="sales_office.geo.lng" class="text-sm leading-5 font-medium text-oakwood-gray-500">Longitude</label>
                <NumberField suffix="º" id="sales_office.geo.lng" width="w-full" step="0.00000000000001" :max="180" :min="-180" v-model="sales_office.geo.lng" />
            </p>
        </div>

        <label for="sales_office.email" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Leads Email</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <input type="email" id="sales_office.email" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" v-model="sales_office.email" />
        </div>

        <label for="sales_office.email" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Phone Number</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <PhoneNumber id="sales_office.email" v-model="sales_office.phone" />
        </div>

        <label for="sales_office.email" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4 lg:row-span-7">Phone Number</label>
        <div v-for="day,i in sales_office.hours" :key="`hours-${i}`" class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 grid lg:grid-cols-6 items-center gap-4">
            <span>{{ new Intl.DateTimeFormat('en-us', { weekday: 'long' }).format( new Date(2024, 0, (i + 1))) }}</span>
            <span class="inline-flex items-center gap-2">
                <ToggleButton :id="`open-${i}`" :checked="day.start !== null" @changed="(e) => {
                    day.start = day.start === null ? '09:00' : null;
                    day.end = day.end === null ? '17:00' : null;
                    day.appointment = day.start === null ? false : (day?.appointment || false);
                }"
                :label="day.start ? 'Open' : 'Closed'" />
                <label :for="`open-${i}`">{{ day.start !== null ? "Open" : "Closed" }}</label>
            </span>
            <span v-if="day.start !== null" :class="(day?.appointment || false) && 'opacity-50'" class="lg:col-span-2 inline-flex items-center gap-2">
                <label :for="`start-${i}`" class="sr-only">From</label>
                <input :disabled="(day?.appointment || false)" :class="(day?.appointment || false) && 'cursor-not-allowed'" :id="`start-${i}`" type="time" v-model="day.start" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" />
                <span aria-hidden="true">-</span>
                <label :for="`end-${i}`" class="sr-only">To</label>
                <input :disabled="(day?.appointment || false)" :class="(day?.appointment || false) && 'cursor-not-allowed'" :id="`end-${i}`" type="time" v-model="day.end" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" />
            </span>
            <span v-if="day.start !== null" class="inline-flex items-center gap-2 lg:col-span-2">
                <ToggleButton :id="`appt-${i}`" :checked="day?.appointment || false" @changed="(val) => {
                    day.appointment = val;
                }" :label="day?.appointment || false ? 'By Appointment' : 'Walk-Ins'" />
                <label :for="`appt-${i}`">By Appointment</label>
            </span>
        </div>
    </fieldset>
       
</template>

<script>
import { formatToCurrency } from '@/helpers/currencyHelpers';
import {FormButton, NumberField, SelectField, ToggleButton, PhoneNumber} from '@/components/form';
import {states} from '@/helpers/globals';
import collection from '@/models/collection';
import { mapState } from 'pinia';
import { useAuthStore } from '@/stores/auth';

export default {
    data() {
        return {
            sales_office: {...collection.meta.sales_office},
            states,
            editing: false
        }
    },
    methods: {
        time(value) {
            value = value !== null ? value : '';
            let time = value.match(/([0-9]{0,2}):?([0-9]{0,2})/);
            time[1] = time[1] != '' ? time[1] : 0;
            time[2] = time[2] != '' ? time[2] : 0;
            return [
                time[1], 
                time[2]
            ];
        },
        edit() {
            if(this.user.level < 2) return;
            this.editing = true;
            let sales_office = JSON.parse(JSON.stringify(this.collection.meta.sales_office));
            this.sales_office = {
                ...this.sales_office,
                ...sales_office
            }
        },
        cancel() {
            this.editing = false;
        },
        save() {
            if(this.user.level < 2) return;
            let collection = this.collection;
            collection.meta.sales_office = {
                ...collection.sales_office,
                ...this.sales_office
            }
            this.$emit('update', collection);
            this.cancel();
        },
        formatToCurrency
    },
    computed: {
        ...mapState(useAuthStore, ['user'])
    },
    emits: [
        'update'
    ],
    props: {
        collection: {
            type: Object,
            required: true
        }
    },
    components: {
        FormButton,
        NumberField,
        SelectField,
        ToggleButton,
        PhoneNumber
    }
}
</script>