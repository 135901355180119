<template>
    <div class="fixed z-10 inset-0 overflow-y-auto" v-show="open">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
            <div class="fixed inset-0 transition-opacity opacity-0" :class="{'ease-out duration-300 opacity-100' : open, 'ease-out duration-300' : !open}">
                <div class="absolute inset-0 bg-oakwood-gray-500 opacity-75"></div>
            </div>
            <section class="max-h-[90vh] overflow-hidden overflow-y-auto inline-block align-bottom bg-white rounded px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full lg:w-auto sm:p-6 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                :class="{'ease-out duration-300 opacity-100 translate-y-0 sm:scale-100' : open}" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button"
                        class="text-oakwood-gray-400 hover:text-oakwood-gray-500 focus:outline-none focus:text-oakwood-gray-500 transition ease-in-out duration-150"
                        aria-label="Close"
                        @click.prevent="$emit('close', false)">
                        <span class="fa fa-close"></span>
                    </button>
                </div>
                <div class="sm:flex sm:items-start gap-4">

                    <div v-if="icon"
                        :class="{'text-oakwood-red-600' : icon == 'error'}"
                        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-oakwood-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <span class="fa text-xl" :class="{'fa-warning' : icon == 'error'}"></span>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:text-left flex-1">
                        <slot name="header"></slot>
                        <div class="mt-2">
                            <slot name="body"></slot>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 flex flex-row justify-end gap-4 sticky -bottom-4 sm:-bottom-6 bg-white py-4 sm:py-6">
                    <slot name="buttons"></slot>
                </div>
            </section>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        icon: {
            default: false
        }
    }
}
</script>