<template>
    <div class="block border-b border-oakwood-gray-200">
        <nav role="tablist">
            <ul class="flex -mb-px px-6 gap-6">
                <li :aria-controls="`panel-${tab.name}`" v-for="tab, i in tabs" :key="`panel-${i}`">
                    <component :is="selectedTab?.name === tab.name ? 'p' : 'router-link'" 
                    :class="selectedTab?.name === tab.name ? 'cursor-default border-oakwood-blue-500 border-b-2 text-oakwood-blue-600' : 'cursor-pointer text-oakwood-gray-500 hover:text-oakwood-gray-700 border-transparent hover:border-oakwood-gray-300'"
                    class="group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none"
                    :to="{name: this.route, params: {tab: tab?.name}}">
                        <span class="far mr-2 text-lg" :class="tab?.icon"></span>
                        <span class="hidden sm:inline">{{ tab?.title }}</span>
                    </component>
                </li>
            </ul>
        </nav>
    </div>
    <section :id="`panel-${selectedTab?.name}`" role="tabpanel" class="py-8 px-5 sm:px-6">
        <slot :tab="selectedTab"></slot>
    </section>
</template>

<script>
export default {
    head() {
        return {
            templateParams: {
                tab: this.selectedTab?.title
            },
            titleTemplate: '%s %separator %tab'
        }
    },
    props: {
        tabs: {
            type: Array,
            require: true
        },
        route: {
            type: String,
            require: true
        }
    },
    computed: {
        selectedTab() {
            return this.tabs.find(tab => tab.name === this.$route.params?.tab) || this.tabs[0];
        }
    }
}
</script>