<template>
    <component :is="element"
        class="col-span-1 flex flex-col text-center rounded shadow">
        <slot></slot>
    </component>
</template>

<script>
export default {
    props: {
        element: {
            type: String,
            default: 'li'
        }
    }
}
</script>