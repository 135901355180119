<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Lots &amp; Parcels</h2>
        <nav class="flex items-end gap-2">
            <div class="rounded-md inline-flex items-center justify-start shadow-sm shadow-oakwood-gray-200 text-oakwood-gray-600">
                <template v-for="item in tabs" :key="item.value">
                    <label :for="`tab-${item.value}`" class="px-3 py-2 rounded-md cursor-pointer" :class="tab === item.value && 'bg-oakwood-blue-500 text-white'"><span class="far mr-2" :class="item.icon"></span>{{item.label}}</label>
                    <input type="radio" v-model="tab" name="tab" :id="`tab-${item.value}`" :value="item.value" class="sr-only">
                </template>
            </div>
        </nav>
    </div>

    <section v-if="tab === 'list'">
        <h3 class="sr-only">List View</h3>

        <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start items-stretch gap-4 mt-4">
            <div class="flex-initial">
                <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Keywords</label>
                <input type="text" id="search" v-model="query.search" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-100 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6" />
            </div>
            <div class="flex-initial">
                <SelectField v-model="query.matches" label="Parcel Matches">
                    <option :value="null">All Parcel Matches</option>
                    <option value="matched">Matched</option>
                    <option value="unmatched">Unmatched</option>
                </SelectField>
            </div>
            <div class="flex-initial">
                <SelectField v-model="query.status" label="Status">
                    <option :value="null">All Statuses</option>
                    <option v-for="status,i in [...new Set(lots.map(lot => lot.kova.Status))]" :key="`status-${i}`" :value="status">{{ status }}</option>
                </SelectField>
            </div>
            <div v-if="hasFilters" class="flex-initial flex flex-col justify-end">
                <FormButton @click="reset" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
            </div>
        </div>
        <PaginationTable v-if="filter" :data="filter">
            <template #headers>
                <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Lot ID
                </th>
                <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Name
                </th>
                <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Collection
                </th>
                <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Address
                </th>
                <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                    Parcel ID
                </th>
            </template>
            <template #columns="{row}">
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-gray-900">
                    {{ row.id }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-gray-900">
                    {{ row.kova?.Name }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-gray-900">
                    {{ collections.find(collection => collection.id === row.collection_id)?.meta.info.name }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-gray-900">
                    {{ row.kova?.StreetAddress }}, {{ row.kova?.City }} {{ row.kova?.ZipCode }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-gray-900">
                    {{ row.parcel_id || '-' }}
                </td>
            </template>
        </PaginationTable>
    </section>
    <section v-else>
        <MapView v-if="lots.length && parcels.length" :sources="sources" :layers="layers" :thecenter="{lng: -111.91173865539844, lat: 40.3635719994329}" @selected="setSelected" class="mt-8">
            <template #legend>
                <dt class="text-oakwood-gray-500 text-sm font-bold mt-4">Key</dt>
                <dd class="text-oakwood-gray-900 text-sm flex items-center gap-1">
                    <span title="Dark Green" class="inline-block w-4 h-4 border border-oakwood-gray-500 bg-oakwood-green-500"></span> New
                </dd>
                <dd class="text-oakwood-gray-900 text-sm flex items-center gap-1">
                    <span title="Light Green" class="inline-block w-4 h-4 border border-oakwood-gray-500 bg-oakwood-green-700"></span> Available
                </dd>
                <dd class="text-oakwood-gray-900 text-sm flex items-center gap-1">
                    <span title="Blue" class="inline-block w-4 h-4 border border-oakwood-gray-500 bg-oakwood-blue-500"></span> Sold
                </dd>
                <dd class="text-oakwood-gray-900 text-sm flex items-center gap-1">
                    <span title="Blue" class="inline-block w-4 h-4 border border-oakwood-gray-500 bg-oakwood-blue-700"></span> Sold Contingent
                </dd>
                <dd class="text-oakwood-gray-900 text-sm flex items-center gap-1">
                    <span title="Red" class="inline-block w-4 h-4 border border-oakwood-gray-500 bg-oakwood-red-500"></span> Closed
                </dd>
                <dd class="text-oakwood-gray-900 text-sm flex items-center gap-1">
                    <span title="Dark Gray" class="inline-block w-4 h-4 border border-oakwood-gray-500 bg-oakwood-gray-800"></span> Void
                </dd>
                <dd class="text-oakwood-gray-900 text-sm flex items-center gap-1">
                    <span title="Light Gray" class="inline-block w-4 h-4 border border-oakwood-gray-500 bg-oakwood-gray-200"></span> Unassigned
                </dd>

            </template>
        </MapView>
        <ModalDialog v-if="selected" :open="selected !== false" @close="close()">
            <template #header>
                <h2 class="text-lg leading-6 font-medium text-oakwood-gray-900" id="modal-headline">
                    Edit Parcel Lot Assignment
                </h2>
            </template>
            <template #body>
                <section class="bg-oakwood-gray-50 p-4 rounded mb-8 grid grid-cols-1 gap-4">
                    <h3 class="sr-only">Parcel Details</h3>
                    <dl class="grid grid-cols-4 gap-1 text-oakwood-gray-500">
                        <dt class="font-bold">Area</dt>
                        <dl class="col-span-3 text-oakwood-gray-900">{{ selected?.properties?.area ? `${Math.round(selected.properties?.area)} sqft` : '-' }}</dl>
                        <dt class="font-bold">Name</dt>
                        <dl class="col-span-3 text-oakwood-gray-900">{{ selected?.properties?.name || '-' }}</dl>
                        <dt class="font-bold">Desc</dt>
                        <dl class="col-span-3 text-oakwood-gray-900">{{ selected?.properties?.desc || '-' }}</dl>
                        <template v-if="lot.id">
                            <dt class="font-bold">Lot</dt>
                            <dl class="col-span-3 text-oakwood-gray-900">{{ currentLot?.kova?.Name }}</dl>
                            <dt class="font-bold">Type</dt>
                            <dl class="col-span-3 text-oakwood-gray-900">{{ currentLot?.kova?.Type }}</dl>
                            <dt class="font-bold">Status</dt>
                            <dl class="col-span-3 text-oakwood-gray-900">{{ currentLot?.kova?.Status }}</dl>
                        </template>
                    </dl>
                </section>
                <p>
                    <SelectField :focus="true" id="lot" @keydown="submit" v-model="lot.id">
                        <option :value="null">Select a Lot...</option>
                        <option v-for="lot, i in availableLots(lot.id)" :key="`lot-${i}`" :value="lot.id">{{ lot.kova.StreetAddress }}, {{ lot.kova.City }} {{ lot.kova.ZipCode }}</option>
                    </SelectField>
                </p>
            </template>
            <template #buttons>
                <FormButton type="button" @click="close()" icon="fa-cancel" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300">
                    Cancel
                </FormButton>
                <FormButton @click="save" icon="fa-arrow-up-from-bracket" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700">
                    Save
                </FormButton>
            </template>
        </ModalDialog>
    </section>
</template>

<script>
import axios from "axios";
import PaginationTable from '@/components/PaginationTable.vue';
import MapView from '@/components/MapView.vue';
import colors from '@/helpers/colors';
import ModalDialog from "../ModalDialog.vue";
import { SelectField, FormButton } from "../form";
const query = {
    search: null,
    matches: null,
    status: null
};

export default {
    data() {
        return {
            tab: "list",
            tabs: [
                {
                    label: "List View",
                    icon: "fa-list",
                    value: "list"
                },
                {
                    label: "Map View",
                    icon: "fa-map",
                    value: "map"
                }
            ],
            lots: [],
            parcels: [],
            selected: false,
            lot: {
                id: null,
                parcel_id: null
            },
            query: {...query}
        }
    },
    methods: {
        async index() {
            await axios.get(`${this.context}/${this.id}/lots`)
                .then((res) => { this.lots = res.data })
                .catch((err) => { console.error(err) });
        },
        async indexParcels() {
            await axios.get(`${this.context}/${this.id}/parcels`)
                .then((res) => { this.parcels = res.data })
                .catch((err) => { console.error(err) });
        },
        async save() {
            if(!this.selected) return;
            await axios.patch(`parcels/${this.lot.parcel_id}`, {lot_id: this.lot.id})
                .then(() => { 
                    this.index();
                    this.selected = false;
                })
                .catch((err) => { console.error(err); });
        },
        setSelected(selected) {
            this.selected = selected;
            this.lot = {
                id: selected?.properties?.lot_id || null,
                parcel_id: selected?.properties?.id || null
            }
        },
        close() {
            this.selected = false;
        },
        availableLots(current) {
            return this.lots.filter(lot => {
                return lot.parcel_id === null || lot.id === current;
            }).sort((a,b) => a.kova.StreetAddress.toLowerCase() > b.kova.StreetAddress.toLowerCase() ? 1 : -1 );
        },
        submit(e) {
            if(e.key === 'Enter') {
                e.preventDefault();
                this.save();
                return false;
            }
            return true;
        },
        reset() {
            this.query = {...query};
        }
    },
    computed: {
        features() {
            return this.parcels
                .map(parcel => {
                    const {id, data} = parcel, 
                    lot = this.lots.find(lot => lot.parcel_id === id) || null;
                    return {
                        ...data,
                        id,
                        properties: {
                            ...data.properties,
                            id,
                            lot_id: lot ? lot.id : false,
                            status: lot?.kova.Status
                        }
                    }
                });
        },
        points() {
            return this.parcels
                .map(parcel => {
                    const {id} = parcel,
                    lot = this.lots.find(lot => lot.parcel_id === id) || null;
                        
                    return {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: parcel.centroid
                        },
                        properties: {
                            id,
                            name: lot?.kova?.LotID,
                            lot_rid: lot?.lot_rid,
                            lot_id: lot?.id,
                            street: lot?.kova?.StreetAddress,
                            city: lot?.kova?.City,
                            zip: lot?.kova?.ZipCode
                        }
                    }
                });
        },
        sources() {
            return [
                {
                    id: 'lots',
                    name: 'Lot Parcels',
                    features: this.features
                },
                {
                    id: 'lot_points',
                    name: 'Lot Points',
                    features: this.points
                }
            ]
        },
        layers() {
            return [
            {
                    id: 'lot-base',
                    type: 'fill',
                    source: 'lots',
                    minzoom: 14,
                    paint: {
                        'fill-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            colors['oakwood-gray'][300],
                            colors['oakwood-gray'][200]
                        ],
                        'fill-opacity': 1,
                        'fill-outline-color': colors.white,
                    },
                    before: 'lot-new'
                },
                {
                    id: 'lot-new',
                    type: 'fill',
                    source: 'lots',
                    minzoom: 14,
                    paint: {
                        'fill-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            colors['oakwood-green'][400],
                            colors['oakwood-green'][500]
                        ],
                        'fill-opacity': 1,
                        'fill-outline-color': colors.white,
                    },
                    filter: [ '==', 'status', 'New']
                },
                {
                    id: 'lot-available',
                    type: 'fill',
                    source: 'lots',
                    minzoom: 14,
                    paint: {
                        'fill-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            colors['oakwood-green'][600],
                            colors['oakwood-green'][700]
                        ],
                        'fill-opacity': 1,
                        'fill-outline-color': colors.white,
                    },
                    filter: [ '==', 'status', 'Available']
                },
                {
                    id: 'lot-closed',
                    type: 'fill',
                    source: 'lots',
                    minzoom: 14,
                    paint: {
                        'fill-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            colors['oakwood-red'][400],
                            colors['oakwood-red'][500]
                        ],
                        'fill-opacity': 1,
                        'fill-outline-color': colors.white,
                    },
                    filter: [ '==', 'status', 'Closed']
                },
                {
                    id: 'lot-sold',
                    type: 'fill',
                    source: 'lots',
                    minzoom: 14,
                    paint: {
                        'fill-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            colors['oakwood-blue'][400],
                            colors['oakwood-blue'][500]
                        ],
                        'fill-opacity': 1,
                        'fill-outline-color': colors.white,
                    },
                    filter: [ '==', 'status', 'Sold']
                },
                {
                    id: 'lot-sold-contingent',
                    type: 'fill',
                    source: 'lots',
                    minzoom: 14,
                    paint: {
                        'fill-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            colors['oakwood-blue'][600],
                            colors['oakwood-blue'][700]
                        ],
                        'fill-opacity': 1,
                        'fill-outline-color': colors.white,
                    },
                    filter: [ '==', 'status', 'SoldContingent']
                },
                {
                    id: 'lot-void',
                    type: 'fill',
                    source: 'lots',
                    minzoom: 14,
                    paint: {
                        'fill-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            colors['oakwood-gray'][800],
                            colors['oakwood-gray'][900]
                        ],
                        'fill-opacity': 1,
                        'fill-outline-color': colors.white,
                    },
                    filter: [ '==', 'status', 'Void']
                },

                {
                    id: 'lot-boundaries',
                    type: 'line',
                    source: 'lots',
                    minzoom: 14,
                    paint: {
                        'line-color': colors.white,
                        'line-width': [
                            'interpolate',
                            ['exponential', 1],
                            ['zoom'],
                            14, .125,
                            24, 4,
                        ]
                    },
                    before: 'lot-labels'
                },
                {
                    id: 'lot-centers',
                    type: 'circle',
                    source: 'lot_points',
                    minzoom: 16,
                    // layout: {
                    //     'icon-image': '1402-circles'
                    // },
                    paint: {
                        'circle-color': colors['white'],
                        'circle-stroke-color': colors['oakwood-gray'][800],
                        'circle-stroke-width': 1,
                        'circle-radius': [
                            'interpolate',
                            ['exponential', 1],
                            ['zoom'],
                            16,
                            2,
                            24,
                            8
                        ]
                    },
                    // before: 'settlement-major-label'
                },
                {
                    id: 'lot-labels',
                    type: 'symbol',
                    source: 'lot_points',
                    layout: {
                        'text-field': ['coalesce', ['get', 'street'], 'Unassigned'],
                        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                        'text-radial-offset': 0,
                        'text-justify': 'center',
                        'text-size': [
                            'interpolate',
                            ['exponential', 1],
                            ['zoom'],
                            16,
                            12,
                            24,
                            16
                        ],
                        'text-max-width': 25
                    },
                    minzoom: 18,
                    paint: {
                        'text-color': colors['oakwood-gray'][900],
                        'text-halo-color': colors['white'],
                        'text-halo-width': 2,
                        'text-translate': [
                            'interpolate',
                            ['exponential', 1],
                            ['zoom'],
                            16,
                            [0,-12],
                            24,
                            [0,-30]
                        ],
                        'text-emissive-strength': 2
                    },
                    // before: 'settlement-major-label'
                },
            ]
        },
        currentLot() {
            return this.lots.find(lot => lot.id === this.lot.id) || false;
        },
        filter() {
            let lots = this.lots;
            switch(this.query.matches) {
                case "matched" :
                    lots = lots.filter(lot => lot.parcel_id !== null);
                    break;
                case "unmatched" :
                    lots = lots.filter(lot => lot.parcel_id === null);
                    break;
            }

            if(this.query.status !== null) {
                lots = lots.filter(lot => lot.kova.Status == this.query.status);
            }

            if(this.query.search !== null && lots.length) {
                lots = lots.filter(lot => {
                    return JSON.stringify(lot?.kova).toLowerCase().includes(this.query.search.toLowerCase()) ||
                        (lot?.parcel_id && `${lot.parcel_id}`.includes(this.query.search)) ||
                        (lot?.id && `${lot.id}`.includes(this.query.search))
                })
            }

            return lots;
        },
        hasFilters() {
            return JSON.stringify(query) !== JSON.stringify(this.query);
        }
    },
    props: {
        id: {
            type: Number,
            required: true
        },
        collections: {
            type: Array,
            default: () => []
        },
        context: {
            type: String,
            required: true
        }
    },
    watch: {
        tab: {
            handler() {
                this.reset();
            }
        }
    },
    components: {
        PaginationTable,
        MapView,
        ModalDialog,
        SelectField,
        FormButton
    },
    created() {
        this.index();
        this.indexParcels();
    }
}
</script>@/components/form