<template>
    <LoadingView v-if="loading" />
    <article v-if="!loading" class="bg-white shadow sm:rounded">

        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-center justify-between gap-8 sticky top-0 bg-white rounded-t-lg">
            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                Upload a New Survey
            </h1>
            <nav class="flex flex-none gap-4 justify-end items-center order-1 lg:order-2">
                <div class="flex-none">
                    <FormButton 
                        class="bg-oakwood-gray-50 hover:opacity-100 transition-opacity delay-75" 
                        icon="fa-cancel" @click="$router.go(-1)">
                        Cancel
                    </FormButton>
                </div>
                <div>
                    <FormButton @click="save" :disabled="!valid" icon="fa-check" class="bg-oakwood-blue-500 text-white">
                        Save
                    </FormButton>
                </div>
            </nav>
        </header>


        <form autocomplete="off" class="grid grid-cols-1 p-5 gap-5">

            <div class="grid grid-cols-1 md:grid-cols-5 pt-5">
                <p class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                    File
                </p>
                <UploadFiles v-model="file" :multiple="false" class="md:col-span-3" format="file" :accept="['text/xml']" />
            </div>

            <div class="border-t border-t-oakwood-gray-200 grid grid-cols-1 md:grid-cols-5 pt-5">
                <label :for="`community`" class="block text-sm font-medium leading-5 text-oakwood-gray-900 sm:mt-px sm:pt-2">
                    Community <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                </label>
                <div class="rounded-md shadow-sm md:col-span-3">
                    <p class="text-sm leading-5 text-oakwood-gray-500 mb-4">The survey must be associated with a community.</p>
                    <SelectField id="community" v-model="item.division_id">
                        <option :value="null">Please Select...</option>
                        <option v-for="community, i in communities.filter(community => community.status != 'inactive')" :key="`collection${i}`" :value="community.id">{{ community.meta?.info?.name }}</option>
                    </SelectField>
                </div>
            </div>

            <div class="border-t border-t-oakwood-gray-200 grid grid-cols-1 md:grid-cols-5 pt-5">
                <label for="filing" class="block text-sm font-medium leading-5 text-oakwood-gray-900 sm:mt-px sm:pt-2">
                    Filing
                </label>
                <div class="rounded-md shadow-sm md:col-span-3">
                    <p class="text-sm leading-5 text-oakwood-gray-500 mb-4">The filing number, when supplied, allows the system to perform accurate matches of parcels to lots.</p>
                    <input id="filing" type="text" v-model="item.meta.filing" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" />
                </div>
            </div>

            <!-- <div class="border-t border-t-oakwood-gray-200 grid grid-cols-1 md:grid-cols-5 pt-5">
                <label :for="`systems`" class="block text-sm font-medium leading-5 text-oakwood-gray-900 sm:mt-px sm:pt-2">
                    Coordinate System
                </label>
                <div class="rounded-md shadow-sm md:col-span-3">
                    <p class="text-sm leading-5 text-oakwood-gray-500 mb-4">The coordinate system of the file is important for the proper conversion of parcel latitude and longitude values.</p>
                    <SelectField id="systems" v-model="item.meta.system">
                        <option :value="null">Please Select...</option>
                        <option v-for="(system, i) in systems" :key="`systems${i}`" :value="system.value">{{ system.label }}</option>
                    </SelectField>
                </div>
            </div> -->

            <!-- <div class="border-t border-t-oakwood-gray-200 grid grid-cols-1 md:grid-cols-5 pt-5">
                <label :for="`county`" class="block text-sm font-medium leading-5 text-oakwood-gray-900 sm:mt-px sm:pt-2">
                    County
                </label>
                <div class="rounded-md shadow-sm md:col-span-3">
                    <p class="text-sm leading-5 text-oakwood-gray-500 mb-4">The county in which the survey was completed will assist with state plane value conversions if they are used.</p>
                    <SelectField id="county" v-model="item.meta.county">
                        <option :value="null">Please Select...</option>
                        <option v-for="(county, i) in availableCounties" :key="`county${i}`" :value="county">{{ county.name }} ({{ county.state }})</option>
                    </SelectField>
                </div>
            </div> -->

            <!-- <div class="border-t border-t-oakwood-gray-200 grid grid-cols-1 md:grid-cols-5 pt-5">
                <label :for="`units`" class="block text-sm font-medium leading-5 text-oakwood-gray-900 sm:mt-px sm:pt-2">
                    Measurements
                </label>
                <div class="rounded-md shadow-sm md:col-span-3">
                    <p class="text-sm leading-5 text-oakwood-gray-500 mb-4">The measurement used in the file is important for any references to area or lengths.</p>
                    <SelectField id="units" v-model="item.meta.unit">
                        <option :value="null">Please Select...</option>
                        <option v-for="(measurement, i) in measurements" :key="`units${i}`" :value="measurement.value">{{ measurement.label }}</option>
                    </SelectField>
                </div>
            </div> -->
        </form>
        
    </article>
</template>

<script>
import axios from 'axios';
import LoadingView from '@/components/LoadingView.vue';
import { UploadFiles, FormButton, SelectField } from '@/components/form';
import { systems, measurements } from '@/helpers/globals';
import { mapActions } from 'pinia';
import { useMessageStore } from "@/stores/message";

export default {
    data() {
        return {
            loading: true,
            counties: [],
            communities: [],
            item: {
                division_id: null,
                filing: null,
                file: null,
                meta: {
                    unit: "USSurveyFoot",
                    system: 'EPSG:4269',
                    county: null
                }
            },
            systems,
            measurements
        }
    },
    methods: {
        ...mapActions(useMessageStore, ['setAlert']),
        save() {
            this.loading = true;
            axios.postForm('surveys', this.item)
                .then(resp => {
                    this.loading = false;
                    this.$router.push({name: 'survey-show', params: {id: resp.data.id}});
                })
                .catch(err => {
                    this.loading = false;
                    console.error(err);
                    this.setAlert({
                        level: 'error',
                        text: err.response.data.message
                    });
                })
        },
        indexCounties() {
            axios.get('surveys/counties')
                .then((resp) => {
                    this.loading = false;
                    this.counties = resp.data.sort((a,b) => a.name > b.name ? 1 : -1)
                })
                .catch(err => {
                    this.loading = false;
                    console.error(err);
                });
        },
        indexCommunities() {
            axios.get('divisions/communities')
                .then(resp => {
                    this.loading = false;
                    this.communities = resp.data.sort((a,b) => a.meta?.info?.name > b.meta?.info?.name ? 1 : -1);
                })
        },
        // suggestCounty(collection) {
        //     let county = this.availableCounties.find(county => county.name.replace(' County', '') == collection.kova.SalesOfficeCounty.replace(' County', ''));
        //     if(county) {
        //         console.log('suggested', county);
        //         this.item.meta.county = county;
        //     }
        // }
    },
    computed: {
        valid() {
            let required = this.item.file && this.item.division_id && this.item.meta.unit && this.item.meta.system;

            required = required && this.item.file.type === 'text/xml';
            return required;
        },
        file: {
            get() {
                return this.item.file == null ? [] : [this.item.file];
            },
            set(value) {
                this.item.file = value[0];
            }
        },
        availableCounties() {
            let counties = this.counties
            if(!this.item.collection_id) return counties;

            let collection = this.collections.find(collection => collection.id == this.item.collection_id) || false;
                
            if(collection && collection.kova?.SalesOfficeState) {
                counties = counties.filter(county => county.state == collection.kova.SalesOfficeState)
            }

            return counties;
        }
    },
    created() {
        this.indexCounties();
        this.indexCommunities();
    },
    watch: {
        // 'item.collection_id' : {
        //     handler(to) {
        //         let collection = this.collections.find(collection => collection.id == to) || false;
        //         if(collection && collection.kova?.SalesOfficeCounty) {
        //             this.suggestCounty(collection);
        //         }
        //     }
        // }
    },
    components: {
        LoadingView, FormButton, SelectField, UploadFiles
    }

}
</script>