<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Videos</h2>
        <FormButton class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" type="button" @click="() => {
            modal = true
        }">
            <span class="far fa-plus-circle mr-1"></span>Add
        </FormButton>
    </div>
    <div class="flex flex-col mt-8">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-oakwood-gray-200 sm:rounded">
                    <table v-if="item.meta.videos.length" class="min-w-full divide-y divide-oakwood-gray-200">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                                    Position
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                                    Title
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                                    Type
                                </th>
                                <th scope="col" class="px-6 py-3 bg-oakwood-gray-50"></th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-oakwood-gray-200">
                            <tr v-for="video, i in item.meta.videos" :key="`video-${i}`">
                                <td class="px-6 py-4 whitespace-no-wrap flex gap-2">
                                    <div class="text-sm leading-5 text-oakwood-gray-900 mr-2">{{ i + 1 }}
                                    </div>
                                    <button type="button" :disabled="i == 0" @click="move(video, 'up')"
                                        :class="{ 'cursor-pointer': i > 0, 'text-oakwood-gray-300 cursor-not-allowed': i == 0 }"><span
                                            class="far fa-arrow-up"></span></button>
                                    <button type="button" :disabled="i == item.meta.videos.length - 1"
                                        @click="move(video, 'down')"
                                        :class="i < item.meta.videos.length - 1 ? 'cursor-pointer' : 'text-oakwood-gray-300 cursor-not-allowed'"><span
                                            class="far fa-arrow-down"></span></button>
                                </td>
                                
                                <td class="px-6 py-4 whitespace-no-wrap">
                                    <a :href="video.url" target="_blank" class="text-sm leading-5 font-medium text-oakwood-blue-600 hover:text-oakwood-blue-900">{{
                                        video.title
                                        }}</a>
                                </td>

                                <td class="px-6 py-4 whitespace-no-wrap">
                                    <div class="text-sm leading-5 text-oakwood-gray-900">
                                        {{ video.type }}
                                    </div>
                                </td>

                                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                                    <FormButton type="button" @click="edit(i)" icon="fa-pencil" class="text-white bg-oakwood-blue-600 hover:bg-oakwood-blue-700">
                                        Edit
                                    </FormButton>
                                    <FormButton type="button" @click="openDelete(i)" icon="fa-ban" class="text-white bg-oakwood-red-600 hover:bg-oakwood-red-700">
                                        Delete
                                    </FormButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else class="py-4 px-5 sm:px-6">
                        There are currently no video media items.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <ModalDialog :open="modal" @close="cancel">
        <template #body>
            <div>
                <h3 class="text-lg leading-6 font-medium text-oakwood-gray-900">
                    Video Editor
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-oakwood-gray-500">
                    Enter the details about a video you wish to include with this {{context}}.
                </p>
            </div>
            <div class="mt-6 sm:mt-5">
                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="title"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Title
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input id="title" v-model="video.title"
                                class="border border-oakwood-gray-300 p-2 rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </div>
                    </div>
                </div>

                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="url"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        URL
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <URLField id="url" v-model="video.url" width="w-full" />
                    </div>
                </div>

                <div v-if="false"
                    class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:pt-5">
                    <label for="type"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Type
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <select id="type" v-model="video.type"
                                class="block p-2 bg-white border border-oakwood-gray-300 rounded w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                <option value="URL">URL</option>
                                <option value="Filename">Filename</option>
                            </select>
                        </div>
                    </div>
                </div>

                
            </div>

        </template>

        <template #buttons>
            <div class="flex justify-end mt-5 pt-5">
                <span class="inline-flex rounded-md shadow-sm">
                    <FormButton type="button" @click="cancel" icon="fa-cancel" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300">
                        Cancel
                    </FormButton>
                </span>
                <span class="ml-3 inline-flex rounded-md shadow-sm">
                    <FormButton type="button" @click="save" icon="fa-save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-500">
                        Save
                    </FormButton>
                </span>
            </div>
        </template>
    </ModalDialog>

    <ModalDialog v-if="deleting" :open="deleting" @close="cancel">
        <template #header>
            <h2 class="text-lg text-oakwood-red-600 font-semibold">Confirm Video Deletion</h2>
        </template>
        <template #body>
            <p class="text-oakwood-gray-900"><strong>Warning!</strong> You are about to delete <em>{{ item.meta.videos[index]?.title }}</em>. Are you sure?</p>
        </template>
        <template #buttons>
            <FormButton type="button" @click="drop" icon="fa-trash-can-check" class="bg-oakwood-red-600 text-white hover:bg-oakwood-red-700">
                Delete
            </FormButton>
            <FormButton type="button" @click="cancel" icon="fa-cancel" class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
        </template>
    </ModalDialog>
</template>

<script>
import ModalDialog from '@/components/ModalDialog.vue';
import { FormButton, URLField} from '@/components/form';

const video = {
        type: 'URL',
        title: '',
        url: ''
    }

export default {
    data() {
        return {
            modal: false,
            index: false,
            video,
            deleting: false
        }
    },
    emits: [
        'update'
    ],
    methods: {
        move(video, direction) {
            const item = this.item;
            let index = item.meta.videos.indexOf(video),
                to = direction == 'up' ? index - 1 : index + 2,
                from = direction == 'up' ? index + 1 : index;
            item.meta.videos.splice(to, 0, video)
            item.meta.videos.splice(from, 1);
            this.$emit('update', item);
        },
        validate() {
            if(this.video.title == '' || this.video.url == '' || this.video.type == '') {
                console.error('Missing required values');
                return false;
            }
            return true;
        },
        edit(index) {
            this.modal = 1;
            this.index = index;
            this.video = this.item.meta.videos[index];
        },
        save() {
            if(!this.validate()) return;
            const item = this.item;
            if (this.index === false) {
                item.meta.videos.push(this.video);
            } else {
                item.meta.videos.splice(this.index, 1, this.video);
            }
            this.$emit('update', item);
            this.cancel();
        },
        drop() {
            if(this.index === false) {
                console.error('You must select an item to delete');
                return;
            }
            const item = this.item;
            item.meta.videos.splice(this.index, 1);
            this.$emit('update', item);
            this.cancel();
        },
        openDelete(i) {
            this.index = i;
            this.deleting = true;
        },
        cancel() {
            this.video = {...video};
            this.index = false;
            this.modal = false;
            this.deleting = false;
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        context: {
            type: String,
            required: true,
        }
    },
    components: {
        FormButton, ModalDialog, URLField
    }
}
</script>