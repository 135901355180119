<template>
    <section class="flex flex-col md:flex-row md:flex-wrap gap-4 my-8">
        <h2 class="sr-only">Filters</h2>
        <div class="w-full md:w-96">
            <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Keywords</label>
            <input v-model="search.string" placeholder="Search media title, caption, and filename" id="search" name="search" type="text"
                autocomplete="off"
                class="p-2 border border-oakwood-gray-200 mt-1 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm rounded-md" />
        </div>
        <!-- <div class="space-y-1">
            <label id="listbox-label" class="block text-sm leading-5 font-medium text-oakwood-gray-700">
                Types
            </label>
            <div class="relative">
                <span class="inline-block w-full rounded-md shadow-sm">
                    <button type="button" @click="() => { search.typeOpt = !search.typeOpt; }" aria-haspopup="listbox" :aria-expanded="search.typeOpt" aria-labelledby="listbox-label"
                        class="cursor-default relative w-full rounded-md border border-oakwood-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-oakwood-blue focus:border-oakwood-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        <span class="block truncate">
                            {{ search.types.length == types.length ? "All Types" : search.types.join(', ')}}
                        </span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg class="h-5 w-5 text-oakwood-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                                <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span>
                    </button>
                </span>
                <div class="absolute mt-1 max-w-max rounded-md bg-white shadow-lg" v-show="search.typeOpt">
                    <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-item-3"
                        class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                        <li v-for="option,k in types" :key="`option-${k}`" :id="`listbox-item-${k}`" role="option" @click="() => { applyType(option) }"
                            class="text-oakwood-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-oakwood-blue-600 focus:text-white focus:bg-oakwood-blue-600">
                            <span class="font-normal block truncate" :class="{'font-semibold' : search.types.includes(option)}">
                                {{ option }}
                            </span>
                            <span v-if="search.types.includes(option)" class="absolute inset-y-0 left-0 flex items-center p-2 fa fa-check">
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
        <div class="space-y-1">
            <label id="homeopt-label" class="block text-sm leading-5 font-medium text-oakwood-gray-700">
                Available Homes
            </label>
            <div class="relative">
                <span class="inline-block w-full rounded-md shadow-sm">
                    <button type="button" @click="() => { search.homeOpt = !search.homeOpt; }" aria-haspopup="listbox" :aria-expanded="search.homeOpt" aria-labelledby="homeopt-label"
                        class="cursor-default relative w-full rounded-md border border-oakwood-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-oakwood-blue focus:border-oakwood-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        <span class="block truncate">
                            {{ search.homes.length == 0 ? "None Specified" : "Selected Homes..."}}
                        </span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg class="h-5 w-5 text-oakwood-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                                <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </span>
                    </button>
                </span>
                <div class="absolute mt-1 max-w-max rounded-md bg-white shadow-lg" v-show="search.homeOpt">
                    <ul tabindex="-1" role="listbox" aria-labelledby="homeopt-label"
                        aria-activedescendant="listbox-item-3"
                        class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                        <li :id="`listbox-item-none`" role="option" @click="() => { applyHome(null) }"
                            class="text-oakwood-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-oakwood-blue-600 focus:text-white focus:bg-oakwood-blue-600">
                            <span class="font-normal block truncate" :class="{'font-semibold' : search.homes.length == 0}">
                                None Specified
                            </span>
                            <span v-if="search.homes.length == 0" class="absolute inset-y-0 left-0 flex items-center p-2 fa fa-check">
                            </span>
                        </li>
                        <li v-for="option,k in homes" :key="`option-${k}`" :id="`listbox-item-${k}`" role="option" @click="() => { applyHome(option) }"
                            class="text-oakwood-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-oakwood-blue-600 focus:text-white focus:bg-oakwood-blue-600">
                            <span class="font-normal block truncate" :class="{'font-semibold' : search.homes.includes(option.sales_order_rid)}">
                                {{ option.kova.LotStreetAddress }}
                            </span>
                            <span v-if="search.homes.includes(option.sales_order_rid)" class="absolute inset-y-0 left-0 flex items-center p-2 fa fa-check">
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <PaginationGrid :data="filtered">
        <template #cards="{items}">
            <Card v-for="item, i in items" :key="`item-${i}`" 
                class="col-span-1 flex flex-col text-center rounded shadow"
                :class="[{'hover:bg-oakwood-blue-100 cursor-pointer relative' : !full}, 
                    selected?.includes(item) ? 'bg-oakwood-red-200 hover:bg-oakwood-red-200' : 'bg-white'
                ]">
                <label class="absolute inset-0" v-if="!full">
                    <input class="hidden" type="checkbox" name="selected" :id="`selected-${i}`" v-model="selected" :value="item">
                </label>
                <div class="flex-1 flex flex-col p-5">
                    <img loading="lazy" class="aspect-square object-cover object-center border border-oakwood-gray-100" :src="item.url" :alt="item.alt">
                    <h3 class="mt-6 text-oakwood-gray-900 text-sm leading-5 font-medium">{{ item.alt }}</h3>
                    <dl v-if="full" class="mt-1 flex-grow flex flex-col justify-between">
                        <dt class="sr-only">Caption</dt>
                        <dd class="text-oakwood-gray-500 text-sm leading-5">{{ item.caption }}</dd>
                    </dl>
                </div>
                <div v-if="full" class="border-t border-oakwood-gray-200">
                    <div class="-mt-px flex">
                        <div class="w-0 flex-1 flex border-r border-oakwood-gray-200">
                            <router-link :to="{name : 'media-edit', params: { id : item.id }}"
                                class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-oakwood-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-oakwood-gray-500 focus:outline-none focus:shadow-outline-oakwood-blue focus:border-oakwood-blue-300 focus:z-10 transition ease-in-out duration-150">
                                <span class="fa fa-edit"></span>
                                <span class="ml-3">Edit</span>
                            </router-link>
                        </div>
                        <div class="-ml-px w-0 flex-1 flex">
                            <button type="button" @click.prevent="() => { confirm = item }"
                                class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-oakwood-gray-700 font-medium border border-transparent rounded-br-lg hover:text-oakwood-gray-500 focus:outline-none focus:shadow-outline-oakwood-blue focus:border-oakwood-blue-300 focus:z-10 transition ease-in-out duration-150">
                                <span class="fa fa-trash"></span>
                                <span class="ml-3">Delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Card>
            
        </template>
        <template #none>
            No matching media items found.
        </template>
    </PaginationGrid>

    <div v-if="!full" class="bg-oakwood-gray-100 w-full p-5 sticky bottom-0 flex justify-end gap-4">
        <button type="button"
            @click="closeModal()"
            class="bg-oakwood-red-600 text-white inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-oakwood-blue-600" 
        >
            <span class="fa fa-cancel mr-1"></span>
            Cancel
        </button>
        <button type="button" 
            @click="saveAttach()"
            class="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-oakwood-blue-600" 
            :class="[selected.length == 0 ? 'bg-oakwood-gray-200 text-oakwood-gray-300 cursor-not-allowed' : 'bg-oakwood-blue-600 hover:bg-oakwood-blue-500 text-white']"
            :disabled="selected.length == 0">
            <span class="fa fa-plus-circle mr-1"></span>
            Attach Selected
        </button>
    </div>

    <ModalDialog v-if="confirm" @close="() => { confirm = false; }" :open="!!confirm" icon="error">
        <template #header>
            <h2 class="text-lg leading-6 font-medium text-oakwood-gray-900" id="modal-headline">
                Confirm Deletion
            </h2>
        </template>
        <template #body>
            <p class="text-sm leading-5 text-oakwood-gray-500">
                Are you sure you want to delete <strong>{{ confirm.alt || confirm.filename }}</strong>? This file will no longer be recoverable once you confirm.
            </p>
        </template>
        <template #buttons>
            <FormButton @click="drop" class="text-white bg-oakwood-red-600 hover:bg-oakwood-red-700">
                <span class="fa fa-trash mr-2"></span>Delete</FormButton>
            <FormButton class="bg-oakwood-gray-200" @click="() => { confirm = false; }">Cancel</FormButton>
        </template>
    </ModalDialog>
</template>

<script>
import Card from './Card.vue';
import ModalDialog from './ModalDialog.vue';
import PaginationGrid from './PaginationGrid.vue';
import FormButton from "./form/FormButton.vue";
import axios from "axios";

let types = {
    Images : [
        'image/png',
        'image/jpg',
        'image/jpeg'
    ],
    PDFs : [
        'application/pdf'
    ],
    Videos : [
        'video/mpeg-4'
    ]
};
export default {
    emits: ['close','attach'],
    data() {

        return {
            search: {
                string: "",
                typeOpt: false,
                types: Object.keys(types),
                homeOpt: false,
                homes: []
            },
            types: Object.keys(types),
            selected: [],
            confirm: false
        }
    },
    computed: {
        filtered() {
            let results = this.media;
            if(this.search.string != '') {
                let regex = RegExp(this.search.string.toLowerCase(), 'i');
                results = results.filter(item => 
                    (item?.alt != null && regex.test(item.alt.toLowerCase())) ||
                    (item?.caption != null && regex.test(item.caption.toLowerCase())) ||
                    (item?.filename != null && regex.test(item.filename.toLowerCase())) ||
                    (item?.meta?.keywords != null && regex.test(item?.meta?.keywords.toLowerCase())) ||
                    (item?.meta?.position != null && regex.test(item?.meta?.position.toLowerCase())
                )
                );
            }

            results = results.filter(item => this.mimeTypes.includes(item.type));

            if(this.search.homes.length > 0)
                results = results.filter(item => 
                    item.sales_orders.find(order => 
                        this.search.homes.includes(order.sales_order_rid)
                    ) !== undefined
                );
            
            return results;
        },
        mimeTypes() {
            return Object.entries(types).filter(([type]) => { 
                return this.search.types.includes(type)
            }).map(([,mimes]) => mimes).flat();
        },
        homes() {
            const homes = this.media
                .map(item => item.sales_orders)
                .flat()
                .reduce((homes,item) => { 
                    if(homes.length == 0 || !homes.map(home => home.pivot.item_id).includes(item.pivot.item_id))
                        return [...homes,item];
                    return homes;
             }, []);
            return homes;
        }
    },
    methods: {
        saveAttach() {
            this.$emit('attach',this.selected);
            this.$emit('close', false);
        },
        closeModal() {
            this.$emit('close', false);
        },
        applyType(option) {
            this.search.types = this.search.types.includes(option) ? 
                this.search.types.filter(type => type != option) : 
                [...this.search.types, option];
            this.search.typeOpt = false;
        },
        applyHome(option) {
            if(option === null) {
                this.search.homes = [];
            } else {
                this.search.homes = this.search.homes.includes(option.sales_order_rid) ? 
                this.search.homes.filter(home => home != option.sales_order_rid) : 
                    [...this.search.homes, option.sales_order_rid];
            }
            this.search.homeOpt = false;
        },
        drop() {
            let media = this.media;
            axios.delete(`attachments/${this.confirm.id}`)
                .then(() => {
                    media = media.filter(item => item.id != this.confirm.id);
                    this.confirm = false;
                    this.$emit('close', media);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },
    props: {
        full: {
            type: Boolean,
            default: true
        },
        media: {
            required: true,
            default: []
        }
    },
    components: {
        ModalDialog,
        FormButton,
        PaginationGrid,
        Card
    }
}
</script>