<template>
    <aside v-if="show" class="rounded-xl p-4 md:mx-8 text-white" :class="type.background" ref="top_msg">
        <div class="flex items-stretch justify-between gap-4">
            <div class="flex-none flex items-center justify-center">
                <div class="text-lg rounded-full w-8 h-8 flex items-center justify-center" :class="type.background">
                    <span class="far" :class="type.icon"></span>
                </div>
            </div>
            <div class="flex-1 flex flex-col gap-2">
                <h3 v-if="type.text" class="text-sm font-bold">
                    {{ type.text }}
                </h3>
                <p class="text-sm">
                    {{ message.text }}
                </p>
            </div>
            <button @click="clear" class="flex-none flex items-start focus:outline-none cursor-pointer">
                <i class="fa-solid fa-xmark fa-lg"></i>
            </button>
        </div>
    </aside>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMessageStore } from "@/stores/message";

export default {
    name: "MessageDisplay",
    data() {
        return {
            types: [
                {
                    type: 'alert',
                    text: 'Alert',
                    background: 'bg-oakwood-blue-600',
                    icon: 'fa-triangle-exclamation'
                },
                {
                    type: 'error',
                    text: 'Error',
                    background: 'bg-oakwood-red-600',
                    icon: 'fa-circle-exclamation'
                },
                {
                    type: 'success',
                    text: 'Success',
                    background: 'bg-oakwood-green-600',
                    icon: 'fa-circle-check'
                },
                {
                    type: 'message',
                    text: 'Message',
                    background: 'bg-oakwood-blue-600',
                    icon: 'fa-circle-info'
                }
            ]
        }
    },
    computed: {
        ...mapState(useMessageStore, ['show', 'message']),
        type() {
            return this.types.find(type => type.type == (this.message?.level || 'error'));   
        },
    },
    methods: {
        ...mapActions(useMessageStore, ['clear'])
    },
    watch: {
        'show': {
            handler(newValue) {
                if (newValue) {
                    setTimeout(() => {
                        const element = this.$refs.top_message;
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }, 100);
                }
            },
            immediate: true
        }
    },
};
</script>