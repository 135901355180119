export default {
    id: null,
    status: null,
    bunit_rid: null,
    parent_id: null,
    group: 'community',
    meta: {
        interactives: [],
        videos: [],
        info: {
            name: null,
            description: null,
            address: {
                street1: null,
                street2: null,
                city: null,
                state: null,
                zip: null,
            },
            geo: {
                lat: 0,
                lng: 0
            },
            website: null,
        },
        sales_office: {
            address: {
                street1: null,
                street2: null,
                city: null,
                state: null,
                zip: null,
                in_community: false
            },
            geo: {
                lat: 0,
                lng: 0
            },
            phone: null,
            hours: [
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false}
            ],
            email: 'hello@oakwoodhomesco.com',
        }
    }
}