export default {
    id: null,
    status: null,
    community_rid: null,
    division_id: null,
    meta: {
        interactives: [],
        videos: [],
        info: {
            name: null,
            description: null,
            status: null,
            website: null,
        },
        sales_office: {
            address: {
                street1: null,
                street2: null,
                city: null,
                state: null,
                zip: null,
                in_collection: false
            },
            geo: {
                lat: 0,
                lng: 0
            },
            phone: null,
            hours: [
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false},
                {start: null, end: null, appointment: false}
            ],
            email: 'hello@oakwoodhomesco.com',
        }
    },
    kova: {},
    created_at: null,
    updated_at: null,
    deleted_at: null
}