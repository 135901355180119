<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Logs</h2>
    </div>
    <div class="flex flex-col mt-8 gap-4">
        <template v-for="(log, i) in logs" :key="`log-${i}`">
            <h3 v-if="i == 0 || log.created_at.date !== logs[i-1]?.created_at?.date" class="text-xl font-semibold mt-4 text-oakwood-gray-900">{{ log.created_at.date }}</h3>
            <div class="border-oakwood-gray-50 border p-6 rounded grid grid-cols-3 gap-4">
                <div class="flex gap-4">
                    <span class="inline-block h-9 w-9 rounded-full text-center fa fa-user bg-oakwood-blue-500 text-white p-2"></span>
                    <div>
                        <a :href="`mailto:${log.user?.email}`" v-if="log.user" class="text-lg font-medium">{{ `${log.user?.first_name} ${log.user?.last_name}`}}</a>
                        <p v-else class="text-lg font-medium"><em>System Process</em></p>
                        <p class="text-sm text-oakwood-gray-700">{{ log.created_at.time }}</p>
                        <p class="text-sm mt-4"><span class="bg-green-300 px-2 inline-block rounded-full">{{ log.action }}</span> #{{ log.id }}</p>
                    </div>
                </div>
                <div class="flex flex-col gap-8">
                    <p class="font-medium text-oakwood-gray-800">Previous Version</p>
                    <ul class="flex flex-col gap-4">
                        <li v-for="change, k in log.models.old" :key="`old-${k}`" class="flex flex-col gap-2 overflow-x-auto">
                            <p class="text-sm font-medium uppercase">{{ k }}</p>
                            <p class="text-oakwood-gray-700">{{ change }}</p>
                        </li>
                    </ul>
                </div>
                <div class="flex flex-col gap-4">
                    <p class="font-medium text-oakwood-gray-800 mb-4">New Version</p>
                    <ul class="flex flex-col gap-4">
                        <li v-for="change, k in log.models.new" :key="`old-${k}`" class="flex flex-col gap-2 overflow-x-auto">
                            <p class="text-sm font-medium uppercase">{{ k }}</p>
                            <p class="text-oakwood-gray-700">{{ change || '-' }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            id: this.$route.params.id,
            logs: [],
        };
    },
    methods: {
        index() {
            axios.get(`logs/${this.context}/${this.id}`)
                .then((res) => {
                    this.logs = res.data.map((log) => {
                        log.created_at = {
                            date: this.formatDate(log.created_at),
                            time: this.formatTime(log.created_at)
                        }
                        return log;
                    });
                })
                .catch((err) => { console.error(err); });
        },
        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {
                year: 'numeric', 
                month: 'long', 
                day: 'numeric'
            }).format(new Date(date));
        },
        formatTime(date) {
            return new Intl.DateTimeFormat('en-US', { 
                hour12: true,
                hour: 'numeric', 
                minute: '2-digit', 
            }).format(new Date(date));
        }
    },
    props: {
        context: {
            type: String,
            required: true
        }
    },
    created() {
        this.index();
    }
};
</script>
