<template>
  <div id="app">
    <div class="h-screen flex bg-oakwood-gray-100" v-if="authenticated">
   
      <MenuNavigation :isOpen="isOpen" @update:isOpen="(e) => { isOpen = e; }" />
      
      <div class="flex flex-col w-0 flex-1">
        <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-oakwood-gray-500 hover:text-oakwood-gray-900 focus:outline-none focus:bg-oakwood-gray-200 transition ease-in-out duration-150" aria-label="Open sidebar" @click="toggleSidebar">
            <!-- Heroicon name: menu -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
          <div class="pt-2 pb-6 md:py-6">

            <MessageDisplay />

            <div class="mx-auto px-4 sm:px-6 md:px-8">
                <!-- content -->
                <div class="py-4">
                    <router-view />
                </div>
                <!-- /End content -->
            </div>
          </div>
        </main>
      </div>
    </div>
    <LoginPage v-else/>
  </div>
</template>

<script>

  import MenuNavigation from '@/components/MenuNavigation.vue';
  import MessageDisplay from '@/components/MessageDisplay.vue';
  import LoginPage from '@/views/LoginPage.vue';
  import { useAuthStore } from '@/stores/auth';
  import { mapState } from 'pinia';
  import { onMounted, onUnmounted } from 'vue';
  const events = ['dragenter', 'dragover', 'dragleave', 'drop']

  export default {
    head() {
        const templateParams = {
          separator: '|',
          site: {
            name: null
          },
          tab: null
        }
        return {
          templateParams,
          title: () => this.$route.meta.title,
          titleTemplate: '%s %separator %site.name'
        }
    },
    setup() {
        function preventDefaults(e) {
            e.preventDefault()
        }

        onMounted(() => {
            events.forEach((name) => {
                document.addEventListener(name, preventDefaults);
            })
        });

        onUnmounted(() => {
            events.forEach((name) => {
                document.body.removeEventListener(name, preventDefaults)
            })
        });
    },
    data() {
      return {
        isOpen: false
      }
    },
    methods: {
      toggleSidebar() {
        this.isOpen = !this.isOpen;
      }
    },
    computed: {
      ...mapState(useAuthStore, ['authenticated'])
    },
    components: {
      MenuNavigation, 
      MessageDisplay, 
      LoginPage
    },
  };
</script>

<style>

</style>
