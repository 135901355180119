<template>
    <div class="flex justify-center items-center inset-0 bg-black bg-opacity-50 z-10" :class="absolute ? 'absolute' : 'fixed w-screen h-screen'">
        <img src="@/assets/images/spin.svg" alt="Loading..." />
    </div>
</template>
  
<script>
export default {
    props: {
        absolute: {
            type: Boolean,
            default: false
        }
    }
}
</script>