<template>
    <LoadingView v-if="loading" />
    <article v-if="!loading" class="bg-white shadow sm:rounded">

        <nav class="flex flex-none gap-4 justify-between items-center order-1 lg:order-2 rounded-t-lg bg-blue-200">
            <div class="py-2 px-6 flex-none">
                <FormButton 
                    class="shadow-none opacity-80 hover:opacity-100 transition-opacity delay-75 bg-white" 
                    icon="fa-chevron-left" :to="{ name: 'survey-list' }">
                    Return to List
                </FormButton>
            </div>
            <div class="py-2 px-6">
                <FormButton type="button" @click="openDelete()" icon="fa-trash" class="text-white bg-oakwood-red-600 hover:bg-oakwood-red-700">
                    Delete
                </FormButton>
            </div>
        </nav>

        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-start gnull-8">
           <div class="flex flex-col lg:flex-row gap-5 sm:gap-6 flex-1">
                <div class="flex flex-col gap-5 sm:gap-6 flex-1 justify-between">
                    <div class="flex flex-col lg:flex-row gap-5 justify-between flex-none">

                        <div class="flex-1 flex flex-col gap-2 lg:gap-0 order-2 lg:order-1">
                            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                                {{ survey.filename }}
                            </h1>
                            <dl class="lg:flex gap-2">
                                <dt class="sr-only">
                                    Division
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 pr-2">
                                    {{ survey.division.meta?.info?.name }}
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <section class="border-t border-l border-oakwood-gray-200 rounded grid grid-cols-1 lg:grid-cols-4">
                        <h2 class="sr-only col-span-full">Basic Information</h2>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center"
                                :class="[survey.parcels.length > 0 ? 'bg-oakwood-green-700' : 'bg-oakwood-red-500']">
                                <span class="far fa-draw-polygon"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Total Parcel Records</dt>
                                <dd class="font-semibold">{{ survey.parcels.length }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-ruler"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Measurement Unit</dt>
                                <dd class="font-semibold">{{ getUnit }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-landmark-flag"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Filing</dt>
                                <dd class="font-semibold">{{ survey.meta?.filing }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-sign-hanging"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Coordinate System</dt>
                                <dd class="font-semibold">{{ getSystem }}</dd>
                            </dl>
                        </aside>
                    </section>

                </div>
                <div class="w-full lg:w-1/5 flex">
                    <div class="text-5xl bg-oakwood-blue-300 text-white p-8 flex items-center justify-center w-full">
                        <span class="far fa-file"></span>
                    </div>
                </div>

            </div>
        </header>
        <TabPanes route="survey-tab" :tabs="tabs" v-slot="{tab}">
            <ParcelsPane v-if="tab.name === 'parcels'" :jobs="job" :parcels="survey.parcels" @update="checkJobs()"></ParcelsPane>
            <template v-if="tab.name === 'debugger'">
                <h2>Raw Data</h2>
                <pre class="mt-8 w-full overflow-x-scroll p-6 bg-oakwood-gray-50 text-oakwood-red-700 shadow-inner shadow-oakwood-gray-200">{{ xml }}</pre>
            </template>
        </TabPanes>

    </article>

    <ModalDialog v-if="deleting" :open="deleting" @close="cancel">
        <template #header>
            <h2 class="text-lg text-oakwood-red-600 font-semibold">Confirm Survey Deletion</h2>
        </template>
        <template #body>
            <p class="text-oakwood-gray-900 mb-4"><strong>Warning!</strong> You are about to delete <em>{{ survey.filename }}</em>. 
            </p>
            <p class="mb-4">Deleting this survey will also <strong>delete related parcels</strong> which will also be <strong>unlinked from matching lots.</strong></p>
            <p>Are you sure?</p>
        </template>
        <template #buttons>
            <FormButton type="button" @click="drop" icon="fa-trash-can-check" class="bg-oakwood-red-600 text-white hover:bg-oakwood-red-700">
                Delete
            </FormButton>
            <FormButton type="button" @click="cancel" icon="fa-cancel" class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
        </template>
    </ModalDialog>
</template>

<script>
import axios from 'axios';
import LoadingView from '@/components/LoadingView.vue';
import { FormButton } from '@/components/form';
import { systems, measurements, handle404 } from '@/helpers/globals';
import ParcelsPane from '@/components/surveys/ParcelsPane.vue';
import ModalDialog from '@/components/ModalDialog.vue';
import TabPanes from '@/components/TabPanes.vue';

let tabs = [
    {
        name: 'parcels',
        icon: 'fa-draw-polygon',
        title: 'Parcels'
    },
    {
        name: 'debugger',
        icon: 'fa-bug-slash',
        title: 'Debugger'
    },
];

export default {
    head() {
        return {
            title: () => `${this.$route.meta.title} %separator ${this.survey?.filename}`
        }
    },
    data() {
        return {
            loading: true,
            survey: {},
            systems,
            measurements,
            tabs,
            xml: null,
            job: false,
            deleting: false
        }
    },
    methods: {
        show() {
            axios.get(`surveys/${this.$route.params?.id}`)
                .then(resp => {
                    this.survey = resp.data;
                    this.loading = false;
                    this.getXMLFile();
                    if(this.survey.parcels.length == 0) {
                        this.checkJobs();
                    }
                })
                .catch(err => {
                    if(err.response.status === 404)
                        handle404(this, err.response.data.message);
                })
        },
        drop() {
            axios.delete(`surveys/${this.survey.id}`)
            .then(() => {
                this.$router.go(-1);
            })
            .catch(err => {
                console.error(err);
            })
        },
        cancel() {
            this.deleting = false;
        },
        openDelete() {
            this.deleting = true;
        },
        getXMLFile() {
            axios.get(`surveys/${this.$route.params?.id}/file`)
                .then(resp => {
                    this.xml = resp.data
                })
        },
        checkJobs() {
            let check = setInterval(
                () => {
                    this.refresh(check);
                },
                2000
            );
            this.refresh(check);
        },
        refresh(check) {
            axios.get(`surveys/${this.$route.params?.id}/jobs`)
            .then(resp => {
                if(resp.data.length == 0) {
                    clearInterval(check);
                    this.job = false;
                    this.show();
                } else {
                    this.job = true;
                }
            })
        }
    },
    computed: {
        getSystem() {
            return systems.find(system => system.value == this.survey.meta?.system)?.label || '';
        },
        getUnit() {
            return measurements.find(unit => unit.value == this.survey.meta?.unit)?.label || '';
        }
    },
    created() {
        this.show();
    },
    components: {
        LoadingView, 
        FormButton, 
        TabPanes,
        ParcelsPane, 
        ModalDialog
    }

}
</script>