import { createApp } from 'vue';
import { createHead, VueHeadMixin } from '@unhead/vue';
import { createPinia } from 'pinia';

const pinia = createPinia();

import App from './App.vue';
import router from './router';
import './assets/css/tailwind.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import * as Sentry from "@sentry/vue";
import axios from 'axios';

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://e12d750f852db072b1367e5437daced6@o240660.ingest.us.sentry.io/4508014390804480",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/feed\.oakwoodhomesco\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.isAxiosError) {
      Sentry.captureException(error, {
        extra: {
          request: error.config,
          response: error.response,
        },
      });
    }
    return Promise.reject(error);
  },
);

app.use(pinia)
  .use(router)
  .use(createHead())
  .mixin(VueHeadMixin)
  .mount('#app');