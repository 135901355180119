export default class {
    constructor(data) {
        return {id: data?.id || null,
            first_name: data?.first_name || null,
            last_name: data?.last_name || null,
            email: data?.email || null,
            photo: data?.photo || null,
            photo_url: data?.photo_url || null,
            file: null,
            meta: {
                phone: data?.meta?.phone || null,
            },
            created_at: data?.created_at || null,
            updated_at: data?.updated_at || null,
            deleted_at: data?.deleted_at || null,
            status: data?.status || 'active',
        }
    }
}