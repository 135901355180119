/** @type {import('tailwindcss').Config} */
export default {
    content: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
    media: false,
    variants: {
      extend: {},
    },
    plugins: [],
    theme: {
        colors: {
            transparent : 'transparent',
            black : '#000000',
            white : '#ffffff',
            gold : '#FFBD25',
            'oakwood-blue': {
                DEFAULT: '#1A3464',
                50: '#C1DEEF',
                100: '#B1D4EB',
                200: '#91BFE2',
                300: '#70A9DA',
                400: '#5090D1',
                500: '#3377C5',
                600: '#2B5FA5',
                700: '#224884',
                800: '#1A3464',
                900: '#0E1B37',
                950: '#091021'
            },
            'oakwood-green': {
                DEFAULT: '#A5B11F',
                50: '#F7FAE0',
                100: '#F3F6CE',
                200: '#EBF0AC',
                300: '#E2EA89',
                400: '#DAE466',
                500: '#D1DE43',
                600: '#C5D425',
                700: '#A5B11F',
                800: '#788117',
                900: '#4C520E',
                950: '#363A0A'
            },
            'oakwood-red': {
                DEFAULT: '#C32222',
                50: '#FDF2F2',
                100: '#FAE1E1',
                200: '#F4BEBE',
                300: '#ED9B9B',
                400: '#E77979',
                500: '#E15656',
                600: '#DB3333',
                700: '#C32222',
                800: '#931A1A',
                900: '#641212',
                950: '#4C0D0D'
            },
            'oakwood-gray': {
                DEFAULT: '#585347',
                50: '#EBE9E6',
                100: '#E2E0DB',
                200: '#D0CCC4',
                300: '#BDB9AE',
                400: '#ABA597',
                500: '#999280',
                600: '#857E6B',
                700: '#6F6859',
                800: '#585347',
                900: '#39362E',
                950: '#292721'
            },
            blue: {
                DEFAULT: '#0C7BCC',
                '200': '#CAE6FA',
                '300': '#BEDCF1',
                '900': '#0C7BCC',
            },
            green: {
                DEFAULT: '#0B8F30',
                '200': '#D6F2D7',
                '300': '#C6E5C7',
                '900': '#0B8F30'
            }
            
        }
    },
  }