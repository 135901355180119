<template>
    <label v-if="!$attrs.id" :for="getId" :class="{'sr-only' : !showLabel}" class="block text-sm font-medium leading-5 text-oakwood-gray-700">{{ label }}</label>
    <p class="border border-oakwood-gray-200 rounded inline-flex items-stretch overflow-hidden">
        <span aria-hidden="true" class="p-2 border-r text-oakwood-gray-400 border-r-oakwood-gray-200">
            <span class="far fa-phone"></span>
        </span>
        <input :id="getId" class="p-2" v-bind="$attrs" type="tel" placeholder="(555) 555-5555" :value="value" @input="update" />
    </p>
</template>

<script>
export default {
    emits: [
        'update:modelValue'
    ],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        getId() {
            return this.$attrs.id || `field-${this.$.uid}`;
        }
    },
    methods: {
        update(e) {
            let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            this.value = e.target.value;
        }
    },
    props: {
        modelValue: {
            required: true
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
        }
    }
}
</script>
