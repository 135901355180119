<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Sales Team</h2>
        <div class="flex gap-2">
            <FormButton icon="fa-link-simple" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" type="button" @click="openSync()">
                Attach
            </FormButton>
            <FormButton icon="fa-plus-circle" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" type="button" @click="edit(agents.length)">
                Create
            </FormButton>
        </div>
    </div>
    <PaginationTable v-if="agents" :data="agents">
        <template #headers>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Name
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Email
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Phone
            </th>
            <th scope="col" class="px-6 py-3 bg-oakwood-gray-50"></th>
        </template>
        <template #columns="{row,i}">
           
            <td class="px-6 py-4 whitespace-no-wrap" :class="row.status !== 'active' && 'opacity-50'">
                <p class="flex gap-2 items-center">
                    <span class="rounded-full w-10 h-10 border border-oakwood-gray-100 overflow-hidden flex items-center justify-center bg-oakwood-blue-400 text-white">
                        <img v-if="row?.photo_url" :src="row.photo_url" class="aspect-square object-cover" />
                        <span v-else class="fas fa-user"></span>
                    </span>
                    <span class="text-sm leading-5 font-medium text-oakwood-gray-900">
                        {{ row?.first_name }} {{ row?.last_name }}
                    </span>
                </p>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-blue-600 hover:text-oakwood-blue-700" :class="row.status !== 'active' && 'opacity-50'">
                <a v-if="row?.email" :href="`mailto:${row.email}`">{{ row.email }}</a>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-oakwood-blue-600 hover:text-oakwood-blue-700" :class="row.status !== 'active' && 'opacity-50'">
                <a v-if="row?.meta?.phone" :href="`tel:${row.meta.phone}`">{{ row.meta.phone }}</a>
            </td>

            <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                <div class="flex gap-2 justify-end items-center">
                    <label :for="`item-${row.id}-status`" class="text-oakwood-gray-700">{{ statuses.find(status => status.value == row.status).label }}</label>
                    <ToggleButton :id="`item-${row.id}-status`" @changed="status(row)" :checked="row.status == 'active'" 
                        :label="row.status == 'active' ? 'Active' : 'Inactive'"/>
                    <FormButton type="button" @click="edit(i)" title="Edit Sales Agent" :disabled="row.status !== 'active'" icon="fa-pencil" class="text-white bg-oakwood-blue-600 hover:bg-oakwood-blue-700">
                        <span class="sr-only">Edit</span>
                    </FormButton>
                    <FormButton type="button" @click="openDetach(i)" title="Detach Sales Agent" icon="fa-link-simple-slash" class="text-white bg-oakwood-red-600 hover:bg-oakwood-red-700">
                        <span class="sr-only">Detach</span>
                    </FormButton>
                    <FormButton type="button" @click="openDelete(i)" title="Permanently Delete Sales Agent" icon="fa-trash" class="text-white bg-oakwood-red-600 hover:bg-oakwood-red-700">
                        <span class="sr-only">Delete</span>
                    </FormButton>
                </div>
            </td>
        </template>
    </PaginationTable>

    <ModalDialog v-if="modal" :open="modal" @close="cancel">
        <template #header>
            <h3 class="text-lg leading-6 font-medium text-oakwood-gray-900">
                Sales Team Member Editor
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-oakwood-gray-500">
                Enter the details about sales team member you wish to include with this collection.
            </p>
        </template>

        <template #body>

            <div class="mt-6 sm:mt-5">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <p class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Photo
                    </p>
                    <UploadFiles @attach="attach" v-model="photo_url" :multiple="false" class="col-span-2" />
                </div>

                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="first_name"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        First Name <span class="text-oakwood-red" title="Value is required">*</span>
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input autocomplete="off" id="first_name" v-model="agent.first_name"
                                class="border border-oakwood-gray-300 p-2 rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </div>
                    </div>
                </div>

                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="last_name"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Last Name <span class="text-oakwood-red" title="Value is required">*</span>
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input autocomplete="off" id="last_name" v-model="agent.last_name"
                                class="border border-oakwood-gray-300 p-2 rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </div>
                    </div>
                </div>

                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="email"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Email
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <input autocomplete="off" type="email" id="email" v-model="agent.email"
                                class="border border-oakwood-gray-300 p-2 rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </div>
                    </div>
                </div>

                <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-oakwood-gray-200 sm:py-5">
                    <label for="phone"
                        class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                        Phone <span class="text-oakwood-red" title="Value is required">*</span>
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <PhoneNumber autocomplete="off" id="phone" v-model="agent.meta.phone"
                                class="border border-oakwood-gray-300 p-2 rounded block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                            </PhoneNumber>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #buttons>
            <div class="flex justify-end mt-5 pt-5">
                <span class="inline-flex rounded-md shadow-sm">
                    <FormButton type="button" @click="cancel" icon="fa-cancel" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300">
                        Cancel
                    </FormButton>
                </span>
                <span class="ml-3 inline-flex rounded-md shadow-sm">
                    <FormButton :disabled="!valid" type="button" @click="save" icon="fa-save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-500">
                        Save
                    </FormButton>
                </span>
            </div>
        </template>
    </ModalDialog>

    <ModalDialog v-if="syncing" :open="syncing" @close="cancel">
        <template #header>
            <h3 class="text-lg leading-6 font-medium text-oakwood-gray-900">
                Attach an Existing Sales Team Member
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-5 text-oakwood-gray-500">
                Select one or more sales team members to attach.
            </p>
        </template>

        <template #body>
            <ul class="grid grid-cols-2 sm:grid-cols-6 gap-4" v-if="available_agents.length">
                <li v-for="agent,i in available_agents" :key="i" class="rounded overflow-hidden"
                    :class="!sync_agents.includes(agent) ? 'border border-oakwood-gray-100 bg-white' : 'border-2 border-oakwood-blue-600 bg-oakwood-blue-50'">
                    <label :for="`agent-${i}`" class="p-6 flex flex-col gap-2">
                        <figure aria-hidden="true" class="rounded-full overflow-hidden bg-oakwood-blue-400 text-blue-300 flex items-center justify-center w-full aspect-square">
                            <img v-if="agent.photo_url" :src="agent.photo_url" class="aspect-square object-cover" />
                            <div v-else class="far fa-user text-5xl"></div>
                        </figure>
                        <p class="text-center text-oakwood-gray-900 font-medium">{{ agent.first_name }} {{ agent.last_name }}</p>
                    </label>
                    <input class="sr-only" type="checkbox" :id="`agent-${i}`" v-model="sync_agents" :value="agent" />
                </li>
            </ul>
            <div v-else class="w-full max-w-xl text-sm text-oakwood-gray-900 p-6 border border-oakwood-gray-100 bg-oakwood-gray-50">
                There are no available sales team members to attach.
            </div>

        </template>

        <template #buttons>
            <FormButton type="button" @click="cancel" icon="fa-cancel" class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
            <FormButton :disabled="sync_agents.length == 0" type="button" @click="sync" icon="fa-link-simple" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700">
                Attach
            </FormButton>
        </template>
    </ModalDialog>

    <ModalDialog v-if="detaching" :open="detaching" @close="cancel">
        <template #header>
            <h2 class="text-lg text-oakwood-blue-600 font-semibold">Confirm Sales Team Member Detachment</h2>
        </template>
        <template #body>
            <p class="text-oakwood-gray-900"><strong>Attention!</strong> You are about to detach <em>{{ agents[index]?.first_name }} {{ agents[index]?.last_name }}</em>. Are you sure?</p>
        </template>
        <template #buttons>
            <FormButton type="button" @click="detach" icon="fa-link-simple-slash" class="bg-oakwood-red-600 text-white hover:bg-oakwood-red-700">
                Detach
            </FormButton>
            <FormButton type="button" @click="cancel" icon="fa-cancel" class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
        </template>
    </ModalDialog>

    <ModalDialog v-if="deleting" :open="deleting" @close="cancel">
        <template #header>
            <h2 class="text-lg text-oakwood-blue-600 font-semibold">Confirm Sales Team Member Deletion</h2>
        </template>
        <template #body>
            <p class="text-oakwood-gray-900"><strong>Attention!</strong> You are about to delete <em>{{ agents[index]?.first_name }} {{ agents[index]?.last_name }}</em>.</p>
            <p class="mt-2"><strong>This will detach this person from all collections and <em>permanently</em> delete their record.</strong></p>
            <p class="mt-2">Are you sure?</p>
        </template>
        <template #buttons>
            <FormButton type="button" @click="purge" icon="fa-trash" class="bg-oakwood-red-600 text-white hover:bg-oakwood-red-700">
                Delete
            </FormButton>
            <FormButton type="button" @click="cancel" icon="fa-cancel" class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
        </template>
    </ModalDialog>
</template>

<script>
import {FormButton, UploadFiles, PhoneNumber, ToggleButton} from '@/components/form';
import ModalDialog from '@/components/ModalDialog.vue';
import PaginationTable from '../PaginationTable.vue';
import axios from 'axios';
import Agent from '@/models/agent';
import { agentStatuses } from '@/helpers/globals';

export default {
    data() {
        return {
            statuses: agentStatuses,
            modal: false,
            index: false,
            agent: new Agent,
            detaching: false,
            deleting: false,
            syncing: false,
            sync_agents: [],
            available_agents: []
        }
    },
    emits: [
        'update'
    ],
    methods: {
        attach(files) {
            this.agent.file = files[0];
        },
        edit(index) {
            this.modal = 1;
            this.index = index;
            if(this.agents[index] !== undefined) 
                this.agent = new Agent(this.agents[index]);
        },
        sync() {
            if(!this.sync_agents.length) 
                return;
            let next = this.agents.length;
            this.sync_agents.forEach(async (agent,i) => {
                await axios.patch(`collections/${this.$route.params.id}/agents/${agent.id}`, {...agent, meta: { order: (i + next) }})
                    .then(res => {
                        this.$emit('update', res.data);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            });         
            this.cancel();
        },
        save() {
            let data = {
                ...this.agent,
                meta: {order: this.index, ...this.agent.meta}
            };

            let handler = data.id ? 
                axios.postForm(`collections/${this.$route.params.id}/agents/${data.id}?_method=PATCH`, data) : 
                axios.postForm(`collections/${this.$route.params.id}/agents`, data);

            handler
            .then(res => {
                this.$emit('update', [...this.agents,res.data]);
                this.cancel();
            })
            .catch(err => {
                console.error(err);
                this.cancel();
            });
        },
        status(item) {
            if(item.status == 'active') {
                axios.delete(`agents/${item.id}`)
                .then(() => {
                    this.$emit('update', this.agents.filter(agent => agent.id !== item.id));
                })
                .catch(err => {
                    console.error(err);
                })
            } else {
                axios.patch(`agents/${item.id}/restore`)
                .then(res => {
                    this.$emit('update', [...this.agents, res.data]);
                })
                .catch(err => {
                    console.error(err);
                })
            }
        },
        getAgents() {
            axios.get(`agents`)
                .then(res => {
                    this.available_agents = res.data.filter(agent => !this.agents.map(agent => agent.id).includes(agent.id) && agent.status == 'active');
                });
        },
        detach() {
            if(this.index === false) {
                console.error('You must select a sales agent to detach');
                return;
            }
            axios.delete(`collections/${this.$route.params.id}/agents/${this.agent.id}`)
                .then(res => {
                    this.$emit('update', res.data);
                    this.cancel();                    
                })
                .catch(err => {
                    console.error(err);
                });            
        },
        purge() {
            if(this.index === false) {
                console.error('You must select a sales agent to delete');
                return;
            }
            axios.delete(`agents/${this.agent.id}/purge`)
            .then(() => {
                this.$emit('update', this.agents.filter(agent => agent.id !== this.agent.id));
                this.cancel();
            })
            .catch(err => {
                console.error(err);
            })
        },
        openDetach(i) {
            this.index = i;
            this.agent = new Agent(this.agents[i]);
            this.detaching = true;
        },
        openDelete(i) {
            this.index = i;
            this.agent = new Agent(this.agents[i]);
            this.deleting = true;
        },
        openSync() {
            this.getAgents();
            this.syncing = true;
        },
        cancel() {
            this.agent = new Agent;
            this.index = false;
            this.modal = false;
            this.detaching = false;
            this.deleting = false;
            this.syncing = false;
        }
    },
    computed: {
        photo_url: {
            get() {
                if(this.agent.photo_url) {
                    return [this.agent.photo_url]
                } else {
                    return this.agent.file == null ? [] : [this.agent.file]
                }
            },
            set(value) {
                this.agent.photo_url = null;
                this.agent.photo = null;
                this.agent.file = value[0];
            }
        },
        valid() {
            return this.agent.first_name && 
                this.agent.last_name && 
                this.agent.meta.phone;
        },
    },
    props: {
        agents: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    components: {
        FormButton, ModalDialog, PaginationTable,
        UploadFiles, PhoneNumber, ToggleButton
    }
}
</script>