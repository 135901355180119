<template>
    <LoadingView v-if="!loaded.collection" />
    <div v-else class="bg-white shadow sm:rounded">

        <nav 
            class="flex flex-none gap-4 justify-between items-center order-1 lg:order-2 rounded-t-lg" 
            :class="{
                'bg-blue-200' : collection.status == 'new', 
                'bg-green-200' : collection.status == 'active', 
                'bg-oakwood-gray-200' : collection.status == 'inactive'
            }">
            <div class="px-5 flex-none">
                <FormButton 
                    class="shadow-none bg-white opacity-80 hover:opacity-100 transition-opacity delay-75" 
                    :class="{
                        'bg-blue-300': collection.status == 'new',
                        'bg-green-300': collection.status == 'active',
                        'bg-oakwood-gray-300': collection.status == 'inactive'
                    }"
                    icon="fa-chevron-left" :to="{ name: 'collection-list' }">
                    Return to List
                </FormButton>
            </div>
            <div class="py-4 px-5 sm:rounded-tr-lg flex justify-end">
                <ToggleButton 
                    v-if="user.level >= 2"
                    id="publish-toggle" 
                    :colors="{
                        on: 'bg-green',
                        off: collection.status == 'new' ? 'bg-blue' : 'bg-oakwood-gray-800',
                        text_on: 'text-green',
                        text_off: collection.status == 'new' ? 'text-blue' : 'text-oakwood-gray-800'
                    }"
                    :checked="collection.status == 'active'" 
                    @changed="changeStatus" label="Set Publishing Status" />
                <span aria-label="Publishing Status"
                    :class="{
                        'bg-blue' : collection.status == 'new', 
                        'bg-green' : collection.status == 'active', 
                        'bg-oakwood-gray-800' : collection.status == 'inactive'
                    }"
                    class="text-white px-2 inline-block text-xs leading-6 font-semibold rounded-full ml-2"
                    v-text="statusLabel"></span>
            </div>
        </nav>
        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex items-start gnull-8">
           <div class="flex flex-col lg:flex-row gap-5 sm:gap-6 flex-1">
                <div class="flex flex-col gap-5 sm:gap-6 flex-1 justify-between">
                    <div class="flex flex-col lg:flex-row gap-5 justify-between flex-none">

                        <div class="flex-1 flex flex-col gap-2 lg:gap-0 order-2 lg:order-1">
                            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                                {{ collection.meta?.info?.name || collection.kova?.MarketingName }}
                                    <span v-if="!['N/S',''].includes(collection?.kova.CustomFields.TextCommWebStatus1)" class="bg-oakwood-gray text-white px-2 rounded-full inline-block text-xs leading-5 relative bottom-1 ml-2">{{ collection?.meta?.info?.callout }}</span>
                            </h1>
                            <dl class="lg:flex gap-2">
                                <dt class="sr-only">
                                    Description
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ collection.kova?.Description }}
                                </dd>
                                <dt class="mt-1 text-sm leading-5 text-oakwood-gray-900">
                                    Kova Community ID:
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ collection.community_rid }}
                                </dd>
                                <dt class="sr-only">
                                    Community ID
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900 border-r border-r-oakwood-gray-200 pr-2">
                                    {{ collection.kova?.CommunityID }}
                                </dd>
                                <dt class="sr-only">
                                    Community FID
                                </dt>
                                <dd class="mt-1 text-sm leading-5 text-oakwood-gray-900">
                                    {{ collection.kova?.CommunityFID }}
                                </dd>
                                
                            </dl>
                        </div>
                    </div>
                    <section class="border-t border-l border-oakwood-gray-200 rounded grid grid-cols-1 lg:grid-cols-4">
                        <h2 class="sr-only col-span-full">Basic Information</h2>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center"
                                :class="[!['Internal', 'Off Market', 'Sold Out','N/S',''].includes(collection.kova.CustomFields.TextCommWebStatus1) ? 'bg-oakwood-green-700' : 'bg-oakwood-red-500']">
                                <span class="far"
                                    :class="[!['Internal', 'Off Market', 'Sold Out','N/S',''].includes(collection.kova.CustomFields.TextCommWebStatus1) ? 'fa-lightbulb-on' : 'fa-lightbulb-slash']"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Sales Status</dt>
                                <dd class="font-semibold">{{ statuses[collection.kova.CustomFields.TextCommWebStatus1] }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-users"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Agents</dt>
                                <dd class="font-semibold">{{ agents && agents?.length }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-house"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Floorplans</dt>
                                <dd class="font-semibold">{{ floorplans.length }}</dd>
                            </dl>
                        </aside>
                        <aside class="flex gap-4 border-b border-r border-oakwood-gray-200 p-4 items-center lg:items-start">
                            <div aria-hidden="true"
                                class="bg-oakwood-blue-500 lg:text-2xl p-1 lg:p-2 rounded text-white flex-none w-8 h-8 lg:w-12 lg:h-12 text-center">
                                <span class="far fa-sign-hanging"></span>
                            </div>
                            <dl class="flex-col gap-4">
                                <dt class="text-oakwood-gray-900 text-sm">Available Homes</dt>
                                <dd class="font-semibold">{{ sales_orders.length }}</dd>
                            </dl>
                        </aside>
                    </section>

                </div>
                <div class="w-full lg:w-1/5 flex">
                    <img v-if="collection.image" class="object-cover"
                        :src="collection.image" :alt="collection.kova?.Name"
                        :title="collection.kova?.Name">
                    <div v-else class="text-5xl bg-oakwood-blue-300 text-white p-8 flex items-center justify-center w-full">
                        <span class="far fa-house"></span>
                    </div>
                </div>

            </div>
        </header>
        <TabPanes route="collection-tab" :tabs="tabs" v-slot="{tab}">
            <InfoPane :collection="collection" @update="prepare" v-if="tab?.name === 'info'" />
            <OfficePane :collection="collection" @update="prepare" v-if="tab?.name === 'office'" />  
            <SalesTeamPane :agents="agents" @update="indexAgents" v-if="tab?.name === 'agents'" />
            <FloorplanPane :floorplans="floorplans" v-if="tab?.name === 'floorplans' && loaded.floorplans" />  
            <SalesOrderPane :sales_orders="sales_orders" :floorplans="floorplans" v-if="tab?.name === 'homes' && loaded.sales_orders" />  
            <ImagePane :parent="collection" context="collection" @update="(image) => {collection.image = image.url}" v-if="tab?.name === 'photos'" />  
            <VideoPane :item="collection" context="collection" @update="prepare" v-if="tab?.name === 'videos'" />
            <InteractivePane :item="collection" context="collection" @update="prepare" v-if="tab?.name === 'interactive'" />
            <LotsPane context="collections" :collections="[collection]" :id="collection.id" v-if="tab?.name === 'lots'"/>
            <LogPane context="collection" v-if="tab?.name === 'logs'" />
    
            <template v-if="tab?.name === 'debugger'">
                <h2>Raw Data</h2>
                <pre>
                    {{ collection }}
                </pre>
            </template>
        </TabPanes>
    </div>
</template>

<script>
import axios from "axios";
import LoadingView from '@/components/LoadingView.vue';
import {FormButton, ToggleButton} from '@/components/form';
import {InfoPane, OfficePane} from '@/components/collections';
import {InteractivePane, ImagePane, VideoPane, FloorplanPane, SalesOrderPane, SalesTeamPane, LotsPane, LogPane} from '@/components/shared';
import { mapState } from "pinia";
import { useAuthStore } from "@/stores/auth";
import collection from '@/models/collection';
import { floorplan, handle404 } from "@/helpers/globals";
import TabPanes from "@/components/TabPanes.vue";

let tabs = [
    {
        name: 'info',
        icon: 'fa-circle-info',
        title: 'General Information'
    },
    {
        name: 'office',
        icon: 'fa-building-flag',
        title: 'Sales Office'
    },
    {
        name: 'agents',
        icon: 'fa-handshake-simple',
        title: 'Sales Team'
    },
    {
        name: 'floorplans',
        icon: 'fa-house',
        title: 'Floorplans'
    },
    {
        name: 'homes',
        icon: 'fa-sign-hanging',
        title: 'Available Homes'
    },
    {
        name: 'photos',
        icon: 'fa-images',
        title: 'Images'
    },
    {
        name: 'videos',
        icon: 'fa-video',
        title: 'Videos'
    },
    {
        name: 'interactive',
        icon: 'fa-panorama',
        title: 'Interactives'
    },
    {
        name: 'lots',
        icon: 'fa-grid',
        title: 'Lots'
    },
    {
        name: 'logs',
        icon: 'fa-timer',
        title: 'History'
    },
    {
        name: 'debugger',
        icon: 'fa-bug-slash',
        title: 'Debugger'
    },
];

export default {
    head() {
        return {
            title: () => `${this.$route.meta.title} %separator ${this.collection?.meta?.info?.name}`
        }
    },
    data() {
        
        return {
            collection: {
                ...collection,
                id: this.$route.params.id
            },
            floorplans: [],
            sales_orders: [],
            agents: [],
            loaded: {
                collection: false,
                floorplans: false,
                sales_orders: false,
                agents: false
            },
            tabs: [],
            statuses: floorplan.ModelStatus
        }
    },
    methods: {
        async show() {
            await axios.get(`collections/${this.collection.id}`)
                .then(res => {
                    if(res.data.meta === null) res.data.meta = {};
                    res.data.meta = {...this.collection?.meta, ...res.data.meta};
                    this.collection = res.data;
                    this.loaded.collection = true;
                    this.indexFloorplans();
                    this.indexSalesOrders();
                    this.indexAgents();
                })
                .catch(err => {
                    if(err.response.status === 404)
                        handle404(this, err.response.data.message);
                });
        },
        async indexFloorplans() {
            this.loaded.floorplans = false;
            await axios.get(`collections/${this.collection.id}/floorplans`)
                .then(res => {
                    this.floorplans = res.data;
                    this.loaded.floorplans = true;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        async indexSalesOrders() {
            this.loaded.sales_orders = false;
            await axios.get(`collections/${this.collection.id}/sales_orders`)
                .then(res => {
                    this.sales_orders = res.data;
                    this.loaded.sales_orders = true;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        async indexAgents() {
            this.loaded.agents = false;
            await axios.get(`collections/${this.collection.id}/agents`)
                .then(res => {
                    this.agents = res.data;
                    this.loaded.agents = true;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        changeStatus(toggle) {
            this.collection.status = toggle ? 'active' : 'inactive';
            this.save();
        },
        save() {
            this.loaded.collection = true;
            axios.patch(`collections/${this.collection.id}`, this.collection)
                .then(res => {
                    this.collection = res.data;
                    this.loaded.collection = true;
                })
                .catch(e => {
                    this.loaded.collection = true;
                    throw e;
                });
        },
        prepare(value) {
            this.collection = value;
            this.save();
        }
    },
    computed: {
        ...mapState(useAuthStore, ['user']),
        lot() {
            let lotId = this.collection.kova?.Lot?.LotID;
            if(!lotId) return '-';
            return parseInt(lotId.split('-').pop());
        },
        statusLabel() {
            switch(this.collection.status) {
                case 'active' :
                    return 'Published';
                case 'inactive' :
                    return 'Unpublished';
                case 'new' : 
                default :
                    return 'New';
            }
        }
    },
    created() {
        this.show();
        this.tabs = tabs.filter(tab => {
            if(this.user.level < 2 && (tab.name == 'debugger' || tab.name == 'logs')) return false;
            return true;
        });
    },
    components: {
        LoadingView,
        ToggleButton,
        FormButton,
        TabPanes,
        InteractivePane,
        VideoPane,
        ImagePane,
        InfoPane,
        OfficePane,
        SalesOrderPane,
        SalesTeamPane,
        FloorplanPane,
        LotsPane,
        LogPane
    },
}
</script>