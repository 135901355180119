export default class {
    constructor(data) {
        return {
            id: data?.id || null,
            type: data?.type || null,
            filename: data?.filename || null,
            path: data?.path || null,
            alt: data?.alt || null,
            caption: data?.caption || null,
            meta: {
                keywords: data?.meta?.keywords || null,
                position: data?.meta?.position || null,
                category: data?.meta?.category || 'home'
            },
            created_at: data?.created_at || null,
            updated_at: data?.updated_at || null,
            deleted_at: data?.deleted_at || null,
            message: data?.message || null,
            saving: data?.saving || false
        }
    }

    static categories = [
        {
            value: 'home',
            label: 'Home'
        },
        {
            value: 'community',
            label: 'Community'
        }
    ];

    static positions = [
        'Elevation',
        'Floorplan',
        'Interior'
    ]
}