import { defineStore } from 'pinia';

const message = {
    text: null,
    level: 'error'
}

export const useMessageStore = defineStore('message', {
    state: () => {
        return {
            message: {...message}
        }
    },
    getters: {
        show() {
            return JSON.stringify(this.message) !== JSON.stringify(message);
        }
    },
    actions: {
        setAlert(value) {
            this.message = value;
        },
        clear() {
            this.message = {...message}
        }
    }
})