<template>
    <LoadingView v-if="isLoading" />
    <header class="flex justify-between items-center">
      <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700 mb-8">Surveys</h1>
      <FormButton :to="{name: 'survey-add', }" icon="fa-add" class="bg-oakwood-blue-500 text-white">Add New Survey</FormButton>
    </header>

    <div class="flex flex-col">
      <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4">
        <div class="mb-4 flex-initial">
          <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
          <input v-model="query.search" placeholder="Search surveys by keyword" id="search" name="search"
            type="text" autocomplete="off"
            class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-300 rounded-md h-8">
        </div>
        <div class="mb-4 flex-initial">
          <SelectField v-model="query.community" label="Community">
              <option :value="null">All Communities</option>
              <option v-for="community,i in communities.filter(community => community.status != 'inactive')" :key="`callout-${i}`" :value="community.id">{{ community.meta?.info?.name }}</option>
          </SelectField>
        </div>
        <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
          <FormButton @click="reset" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
        </div>
      </div>
  
      <PaginationTable v-if="!isLoading" :data="filtered" :limit="5">
        <template #headers>
          <th
            class="px-6 py-3">
            File Name
          </th>
          <th
            class="px-6 py-3">
            Community
          </th>
          <th
            class="px-6 py-3">
            Filing
          </th>
          <th
            class="px-6 py-3">
            Created Date
          </th>
          <th></th>
        </template>
        <template #columns="{row}">
          <td class="block sm:table-cell">
            <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'survey-show', params: { id: row.id } }">
              <div class="flex items-center gap-2">
                <div class="flex-shrink-0 text-2xl text-oakwood-blue-500">
                  <span class="fas fa-file-xml"></span>
                </div>
                <div class="text-sm leading-5 font-medium text-oakwood-gray-900">
                  {{ row.filename }}
                </div>
              </div>
            </router-link>
          </td>
          <td class="sm:whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block sm:table-cell">
            <router-link class="block px-6 sm:py-4 whitespace-no-wrap" :to="{ name: 'survey-show', params: { id: row.id } }">
                <p class="text-sm leading-5 text-oakwood-gray-900">
                    {{ getCommunityName(row.division_id) }}
                </p>
            </router-link>
          </td>
          <td class="sm:whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block sm:table-cell">
            <router-link class="block px-6 sm:py-4 whitespace-no-wrap" :to="{ name: 'survey-show', params: { id: row.id } }">
                <p class="text-sm leading-5 text-oakwood-gray-900">
                    {{ row?.meta?.filing }}
                </p>
            </router-link>
          </td>
          <td class="sm:whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block sm:table-cell">
            <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'survey-show', params: { id: row.id } }">
            {{ getDate(row.created_at) }}
            </router-link>
          </td>
          <td class="sm:whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block sm:table-cell">
            <FormButton icon="fa-trash" @click="openDelete(row)" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white"><span class="sr-only">Delete</span></FormButton>
          </td>
        </template>
      </PaginationTable>
    </div>

    <ModalDialog v-if="deleting" :open="deleting" @close="cancel">
        <template #header>
            <h2 class="text-lg text-oakwood-red-600 font-semibold">Confirm Survey Deletion</h2>
        </template>
        <template #body>
            <p class="text-oakwood-gray-900 mb-4"><strong>Warning!</strong> You are about to delete <em>{{ survey.filename }}</em>. 
            </p>
            <p class="mb-4">Deleting this survey will also <strong>delete related parcels</strong> which will also be <strong>unlinked from matching lots.</strong></p>
            <p>Are you sure?</p>
        </template>
        <template #buttons>
            <FormButton type="button" @click="drop" icon="fa-trash-can-check" class="bg-oakwood-red-600 text-white hover:bg-oakwood-red-700">
                Delete
            </FormButton>
            <FormButton type="button" @click="cancel" icon="fa-cancel" class="text-oakwood-gray-700 border border-oakwood-gray-300 hover:text-oakwood-gray-500">
                Cancel
            </FormButton>
        </template>
    </ModalDialog>

</template>

<script>
import axios from "axios";
import LoadingView from '@/components/LoadingView.vue';
import {FormButton,SelectField} from '@/components/form';
import ModalDialog from "@/components/ModalDialog.vue";
import PaginationTable from "@/components/PaginationTable.vue";
import { safe } from "@/helpers/regex";

const query = {
    search: '',
    community: null
}

export default {
    data() {
        return {
            isLoading: true,
            query: {...query},
            surveys: [],
            communities: [],
            survey: {},
            deleting: false
        }
    },
    components: {
        LoadingView, FormButton, SelectField, ModalDialog, PaginationTable
    },
    methods: {
        index() {
            axios.get('surveys')
                .then(resp => {
                    this.surveys = resp.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error(error);
                })
        },
        indexCommunities() {
          axios.get('divisions/communities')
                .then(resp => {
                    this.communities = resp.data.sort((a,b) => a.meta?.info?.name > b.meta?.info?.name ? 1 : -1);
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error(error);
                })
        },
        getCommunityName(id) {
          return this.communities.find((community) => community.id == id)?.meta?.info?.name ?? ''
        },
        getDate(date) {
          return new Intl.DateTimeFormat('en-US',{
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
            timeZone: "America/Denver",
          }).format(new Date(date));
        },
        reset() {
            this.query = {...query};
        },
        drop() {
            axios.delete(`surveys/${this.survey.id}`)
            .then(() => {
                this.index();
                this.deleting = false;
            })
            .catch(err => {
                console.error(err);
            })
        },
        cancel() {
          this.survey = {};
          this.deleting = false;
        },
        openDelete(item) {
          this.survey = item;
          this.deleting = true;
        },
    },
    computed: {
        hasFilters() {
            return JSON.stringify(this.query) != JSON.stringify(query);
        },
        filtered() {
            let results = this.surveys;
            
            if (this.query.search !== '') {
            const regex = new RegExp(safe(this.query.search), 'i');
            results = results.filter(item => regex.exec(item.filename) || 
                regex.exec(JSON.stringify(item.meta))
                );
            } 

            if (this.query.community !== null) {
              results = results.filter(item => item.division_id == this.query.community);
            }
            
            return results;
        },
    },
    created() {
      this.index();
      this.indexCommunities();
    }
}
</script>