<template>
    <a v-if="$attrs?.href || false" :class="classes" v-bind="$attrs">
        <span v-if="icon" class="far" :class="icon"></span><slot></slot>
    </a>
    <router-link v-else-if="$attrs.to || false" :class="classes" v-bind="$attrs">
        <span v-if="icon" class="far" :class="icon"></span><slot></slot>
    </router-link>
    <button v-else type="button" :class="[classes, ($attrs.disabled ? 'opacity-30 cursor-not-allowed' : 'cursor-pointer')]" v-bind="$attrs">
        <span v-if="icon" class="far" :class="icon"></span><slot></slot>
    </button>
</template>

<script>
export default {
    data() {
        return {
            classes : "inline-flex items-center gap-1 rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        }
    },
    props: {
        icon: {
            type: [Boolean, String],
            default: false,
            required: false
        },
    }
}
</script>