<template>
    <label v-if="!id" :for="getId" :class="{'sr-only' : !showLabel}">{{ label }}</label>
    <div class="border border-oakwood-gray-200 rounded shadow-sm focus:border-oakwood-blue-400 inline-flex items-start" :class="width">
        <div class="text-oakwood-gray-400 border-r border-r-oakwood-gray-200 p-2"><span class="far fa-globe-pointer"></span></div>
        <input 
            type="url" 
            class="p-2 border-none w-full" 
            :id="getId" 
            :pattern="pattern"
            :placeholder="placeholder"
            v-model="value" 
        />  
        <div aria-hidden="true" :title="valid ? 'URL is validated' : 'URL is invalid'" class="flex-0 p-2 rounded-r border-l border-l-oakwood-gray-200" :class="valid ? 'text-green bg-green-200' : 'text-oakwood-red bg-oakwood-red-100'">
            <span class="far" :class="valid ? 'fa-thumbs-up' : 'fa-thumbs-down'"></span>
        </div>  
    </div>
</template>

<script>
export default {
    data() {
        return {
            pattern: /^(http|https):\/\/([a-z]+\.)+[a-z]{2,}\/*.*/
        }
    },
    emits: [
        'update:modelValue'
    ],
    props: {
        id: {
            type: [Boolean, String],
            default: false
        },
        placeholder: {
            type: String,
            default: "https://website.com/my-url"
        },
        modelValue: {
            required: true
        },
        width: {
            type: String,
            default: "w-full sm:w-1/2"
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        valid() {
            if(!this.value) return true;
            let matches = this.pattern.exec(this.value);
            return matches && matches[1];
        },
        getId() {
            return this.id || `field-${this.$.uid}`;
        }
    }  
}
</script>

<style>
</style>