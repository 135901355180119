<template>
    <figure class="grid grid-cols-6 gap-4">
        <div class="col-span-6 order-2 sm:col-span-1 sm:order-1">
            <dl>
                <dt class="text-oakwood-gray-500 text-sm font-bold">Coordinates</dt>
                <dd class="text-oakwood-gray-900 text-sm">Lat: {{ cursor?.lat }}</dd>
                <dd class="text-oakwood-gray-900 text-sm">Lng: {{ cursor?.lng }}</dd>
                <slot name="legend"></slot>
            </dl>
        </div>
        <div ref="mapContainer" class="col-span-6 order-1 sm:col-span-5 sm:order-2 flex flex-col w-full min-h-screen md:min-h-[50vh] text-xs text-oakwood-gray-500"></div>
        <figcaption class="sr-only">This is the map of the parcels for this community.</figcaption>
    </figure>
</template>

<script>
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN;
let map;

export default {

    emits: [
        'selected'
    ],
    data: () => ({
        map: null,
        initialLocation: null,
        hover: null,
        cursor: {
            lat: null,
            lng: null
        }
    }),
    methods: {
        addSources() {
            this.sources.forEach(source => {
                map.addSource(
                    source.id, 
                    {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            name: source.name,
                            crs: {
                                type: 'name',
                                properties: {
                                    name: source.name
                                } 
                            },
                            features: source.features || []
                        }
                    }
                );
            });
        },
        updateSources() {
            this.sources.forEach(source => {
                map.getSource( source.id ).setData( 
                    {
                        type: 'FeatureCollection',
                        name: source.name,
                        crs: {
                            type: 'name',
                            properties: {
                                name: source.name
                            } 
                        },
                        features: source.features || [] 
                    }
                );
            });
        },
        addLayers() {
            this.layers.forEach(layer => {
                const settings = {
                    id: layer.id,
                    type: layer.type,
                    source: layer.source,
                    paint: layer.paint || {},
                    minzoom: layer.minzoom || 0,
                }

                if(layer?.layout) settings.layout = layer.layout;
                if(layer?.filter) settings.filter = layer.filter;

                map.addLayer(
                    settings,
                    // layer.before || "settlement-major-label"
                );

                map.on('click', layer.id, (e) => {
                    if(layer.id == 'lot-base') {
                        this.$emit('selected', e.features[0]);
                    }
                });

                map.on('mousemove', layer.id, (e) => {
                    map.getCanvas().style.cursor = 'pointer';
                    if(this.hover !== e.features[0].properties.id) {
                        this.hover = e.features[0].properties.id;
                    }
                })
                map.on('mouseleave', layer.id, () => {
                    map.getCanvas().style.cursor = '';
                    this.hover = null;
                })
            });
        },
        fitBounds() {
            let bounds = new mapboxgl.LngLatBounds();

            this.coordinates
                .forEach(feature => {
                    bounds.extend(feature);
                });

            map.fitBounds(bounds, {
                pitch: this.pitch,
                bearing: this.bearing,
                padding: this.padding,
                linear: false
            });
        },
        setCursor(data) {
            this.cursor = {
                lat: data.lngLat.lat,
                lng: data.lngLat.lng
            }
        }
    },
    computed: {
        coordinates() {
            let i = 0;
            let flatten = (arr, catcher) => {
                return arr.reduce((acc, cur, idx) => 
                    {
                        if(Array.isArray(cur)) {
                            flatten(cur, acc);
                        } else {
                            if(acc[i] === undefined) {
                                acc[i] = {
                                    lng: null,
                                    lat: null
                                }
                            }
                            acc[i][(idx === 0 ? 'lng' : 'lat')] = cur;
                            i = idx > 0 ? i+1 : i;
                        }
                        return acc;
                    },
                    catcher
                );
            }

            return flatten(this.sources.flatMap(source => source.features.map(feature => feature.geometry.coordinates)), []);
        },
        centroid() {
            let lng = this.coordinates.map(coordinate => coordinate.lng),
            lat = this.coordinates.map(coordinate => coordinate.lat);

            return {
                lng: ( Math.max(...lng) + Math.min(...lng) ) / 2,
                lat: ( Math.max(...lat) + Math.min(...lat) ) / 2
            };
        }
    },
    mounted() {
        map = new mapboxgl.Map({
            container: this.$refs.mapContainer,
            style: import.meta.env.VITE_APP_MAPBOX_STYLE,
            center: this.center || this.centroid,
            bearing: this.bearing,
            pitch: this.pitch,
            zoom: this.zoom,
        })
        .on('mousemove', (e) => {
            this.setCursor(e);
        })
        .on('style.load', () => {
            this.addSources();
            this.addLayers();
        })
        .on('click', (e) => {
            const bbox = [
                [e.point.x, e.point.y],
                [e.point.x, e.point.y]
            ];

            const selectedFeatures = map.queryRenderedFeatures(bbox);

            console.log(selectedFeatures);
        })
        .on('load', () => {
            this.fitBounds();
        });

    },
    created(){
        this.initialLocation = {
            ...this.center,
            bearing: this.bearing,
            pitch: this.pitch,
            zoom: this.zoom
        };
    },
    watch: {
        'hover': {
            handler(newval, oldval) {
                if(oldval !== null) {
                    map.setFeatureState(
                        {source: 'lots', id: oldval},
                        {hover: false}
                    );
                }
                map.setFeatureState(
                    {source: 'lots', id: newval},
                    {hover: true}
                );
            }
        },
        'sources': {
            handler() {
                this.updateSources();
            }
        }
    },  

    unmounted() {
        map.remove();
        map = null;
    },
    props: {
        center: {
            default: false
        },
        bearing: {
            type: Number,
            default: 0
        },
        pitch: {
            type: Number,
            default: 10
        },
        zoom: {
            type: Number,
            default: 14
        },
        show: {
            type: Object,
            default: () => ({
                roads: false,
                common: false,
                new: true,
                released: true,
                sold: true,
            })
        },
        padding: {
            type: Number,
            default: 50
        },
        sources: {
            type: Array,
            default: () => []
        },
        layers: {
            type: Array,
            default: () => []
        }
    },
}
</script>

<style>
:root .mapboxgl-ctrl-logo {
    @apply hidden;
}
:root .mapboxgl-ctrl-attrib-inner *  {
    @apply text-oakwood-gray-300;
}
</style>