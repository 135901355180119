<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Parcels</h2>
    </div>

    <div class="flex flex-col flex-wrap md:flex-row md:justify-start items-stretch gap-4">
        <div class="flex gap-4 items-end flex-1">
            <div class="mb-4 flex-initial">
                <SelectField v-model="query.status" label="Status">
                    <option :value="null">All Statuses</option>
                    <option v-for="status,i in statuses" :key="`callout-${i}`" :value="status">{{ status }}</option>
                </SelectField>
            </div>
            <div v-if="hasFilters" class="mb-4 flex-initial flex flex-col justify-end">
                <FormButton @click="reset" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
            </div>
        </div>
        <div class="mb-4 flex-initial pr-4 flex flex-col justify-end">
          <FormButton icon="fa-arrows-rotate" @click="reprocess" class="bg-oakwood-blue-500 text-white">Regenerate Parcels</FormButton>
        </div>
      </div>

    <PaginationTable :data="filtered" class="flex flex-col mt-8" v-if="!jobs">
        <template #headers>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                ID
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Name
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Linked Lot
            </th>
            <th scope="col"
                class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                Created Date
            </th>
        </template>
        <template #columns="{row}">
            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name:'parcel-show', params: { id: row.id } }" class="block px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600">
                    {{ row.id }}
                </router-link>
            </td>

            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name:'parcel-show', params: { id: row.id } }" class="block px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600">
                    {{ row.data.properties?.name }}
                </router-link>
            </td>

            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name:'parcel-show', params: { id: row.id } }" class="block px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600">
                    {{ row.lot?.kova?.Name }}
                </router-link>
            </td>

            <td class="whitespace-no-wrap text-sm leading-5 text-oakwood-gray-900">
                <router-link :to="{ name:'parcel-show', params: { id: row.id } }" class="block px-6 py-4 text-oakwood-blue-500 hover:text-oakwood-blue-600">
                    {{ getDate(row.created_at) }}
                </router-link>
            </td>
        </template>
    </PaginationTable>
    <div v-else class="flex flex-col gap-2 items-center justify-center">
        <img class="w-24" src="@/assets/images/spin.svg" alt="Loading..." />
        <p>File Processing...</p>
    </div>
       
</template>

<script>
// import axios from 'axios';
import {FormButton, SelectField} from '@/components/form';
import PaginationTable from "@/components/PaginationTable.vue";
import axios from 'axios';

const query = {
    status: null,
}

export default {
    data() {
        return {
            query: {...query},
            statuses: [
                'linked',
                'unlinked'
            ]
        }
    },
    emits: [
        'update'
    ],
    props: {
        parcels: {
            type: Array,
            required: true,
            default: () => []
        },
        jobs: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getDate(date) {
            return new Intl.DateTimeFormat('en-US', {}).format(new Date(date));
        },
        reset() {
            this.query = {...query};
        },
        reprocess() {
            axios.get(`surveys/${this.$route.params.id}/reprocess`)
                .then(res => {
                    this.$emit('update');
                })
                .catch(err => {
                    console.error(err);
                });
        }
    },
    computed: {
        filtered() {
            let results = this.parcels;

            switch(this.query.status) {
                case null :
                    break;
                case 'linked' :
                    results = results.filter(item => item.lot !== null);
                    break;
                case 'unlinked' :
                    results = results.filter(item => item.lot === null);
                    break;
            }

            return results;
        },
        hasFilters() {
            return JSON.stringify(this.query) !== JSON.stringify(query);
        }
    },
    components: {
        PaginationTable,
        SelectField,
        FormButton
    }
}
</script>